import { Spin, SpinProps } from "antd";

interface LoadingScreenProps extends SpinProps {
  wrapperStyle?: React.CSSProperties;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  wrapperStyle = {},
  ...spinProps
}) => {
  return (
    <div
      style={{
        right: 0,
        bottom: 0,
        zIndex: 99999,
        width: "100%",
        height: "100%",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...wrapperStyle,
      }}
    >
      <Spin {...spinProps} />
    </div>
  );
};

export default LoadingScreen;
