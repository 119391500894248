import { CSSProperties } from "react";

export interface IconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
}

export const IconReport: React.FC<IconProps> = ({
  color = "currentColor",
  width = 16,
  height = 16,
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M15 23.2152H9C3.57 23.2152 1.25 20.8952 1.25 15.4652V9.46521C1.25 4.03521 3.57 1.71521 9 1.71521H15C20.43 1.71521 22.75 4.03521 22.75 9.46521V15.4652C22.75 20.8952 20.43 23.2152 15 23.2152ZM9 3.21521C4.39 3.21521 2.75 4.85521 2.75 9.46521V15.4652C2.75 20.0752 4.39 21.7152 9 21.7152H15C19.61 21.7152 21.25 20.0752 21.25 15.4652V9.46521C21.25 4.85521 19.61 3.21521 15 3.21521H9Z"
      fill={color}
    />
    <path
      d="M15.5 19.7152C13.98 19.7152 12.75 18.4852 12.75 16.9652V7.96521C12.75 6.44521 13.98 5.21521 15.5 5.21521C17.02 5.21521 18.25 6.44521 18.25 7.96521V16.9652C18.25 18.4852 17.02 19.7152 15.5 19.7152ZM15.5 6.71521C14.81 6.71521 14.25 7.27521 14.25 7.96521V16.9652C14.25 17.6552 14.81 18.2152 15.5 18.2152C16.19 18.2152 16.75 17.6552 16.75 16.9652V7.96521C16.75 7.27521 16.19 6.71521 15.5 6.71521Z"
      fill={color}
    />
    <path
      d="M8.5 19.7152C6.98 19.7152 5.75 18.4852 5.75 16.9652V13.4652C5.75 11.9452 6.98 10.7152 8.5 10.7152C10.02 10.7152 11.25 11.9452 11.25 13.4652V16.9652C11.25 18.4852 10.02 19.7152 8.5 19.7152ZM8.5 12.2152C7.81 12.2152 7.25 12.7752 7.25 13.4652V16.9652C7.25 17.6552 7.81 18.2152 8.5 18.2152C9.19 18.2152 9.75 17.6552 9.75 16.9652V13.4652C9.75 12.7752 9.19 12.2152 8.5 12.2152Z"
      fill={color}
    />
  </svg>
);

export const IconNormalClasses: React.FC<IconProps> = ({
  color = "currentColor",
  width = 16,
  height = 16,
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M12.01 17.4651C11.16 17.4651 10.3 17.2451 9.63 16.8151L3.61 12.8851C2.49 12.1551 1.82 10.9251 1.82 9.58512C1.82 8.24512 2.49 7.01512 3.61 6.28512L9.64 2.36512C10.98 1.49512 13.07 1.49512 14.4 2.37512L20.39 6.30512C21.5 7.03512 22.17 8.26512 22.17 9.59512C22.17 10.9251 21.5 12.1551 20.39 12.8851L14.4 16.8151C13.73 17.2551 12.87 17.4651 12.01 17.4651ZM12.01 3.21512C11.44 3.21512 10.87 3.34512 10.46 3.62512L4.44 7.54512C3.74 8.00512 3.33 8.74512 3.33 9.58512C3.33 10.4251 3.73 11.1651 4.44 11.6251L10.46 15.5551C11.29 16.0951 12.75 16.0951 13.58 15.5551L19.57 11.6251C20.27 11.1651 20.67 10.4251 20.67 9.58512C20.67 8.74512 20.27 8.00512 19.57 7.54512L13.58 3.61512C13.16 3.35512 12.59 3.21512 12.01 3.21512Z"
      fill={color}
    />
    <path
      d="M12 23.2152C11.56 23.2152 11.11 23.1552 10.75 23.0352L7.56 21.9752C6.05 21.4752 4.86 19.8252 4.87 18.2352L4.88 13.5452C4.88 13.1352 5.22 12.7952 5.63 12.7952C6.04 12.7952 6.38 13.1352 6.38 13.5452L6.37 18.2352C6.37 19.1752 7.15 20.2552 8.04 20.5552L11.23 21.6152C11.63 21.7452 12.37 21.7452 12.77 21.6152L15.96 20.5552C16.85 20.2552 17.63 19.1752 17.63 18.2452V13.6052C17.63 13.1952 17.97 12.8552 18.38 12.8552C18.79 12.8552 19.13 13.1952 19.13 13.6052V18.2452C19.13 19.8352 17.95 21.4752 16.44 21.9852L13.25 23.0452C12.89 23.1552 12.44 23.2152 12 23.2152Z"
      fill={color}
    />
    <path
      d="M21.4 16.2151C20.99 16.2151 20.65 15.8751 20.65 15.4651V9.46509C20.65 9.05509 20.99 8.71509 21.4 8.71509C21.81 8.71509 22.15 9.05509 22.15 9.46509V15.4651C22.15 15.8751 21.81 16.2151 21.4 16.2151Z"
      fill={color}
    />
  </svg>
);

export const IconExtraClasses: React.FC<IconProps> = ({
  color = "currentColor",
  width = 16,
  height = 16,
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M12 22.5451C11.7 22.5451 11.4 22.4751 11.15 22.3351C9.28 21.3151 5.99 20.2351 3.93 19.9651L3.64 19.9251C2.33 19.7651 1.25 18.5351 1.25 17.2051V5.1251C1.25 4.3351 1.56 3.6151 2.13 3.0951C2.7 2.5751 3.44 2.3251 4.22 2.3951C6.42 2.5651 9.74 3.6651 11.62 4.8451L11.86 4.9851C11.93 5.0251 12.08 5.0251 12.14 4.9951L12.3 4.8951C14.18 3.7151 17.5 2.5951 19.71 2.4051C19.73 2.4051 19.81 2.4051 19.83 2.4051C20.56 2.3351 21.31 2.5951 21.87 3.1151C22.44 3.6351 22.75 4.3551 22.75 5.1451V17.2151C22.75 18.5551 21.67 19.7751 20.35 19.9351L20.02 19.9751C17.96 20.2451 14.66 21.3351 12.83 22.3451C12.59 22.4851 12.3 22.5451 12 22.5451ZM3.98 3.8851C3.66 3.8851 3.37 3.9951 3.14 4.2051C2.89 4.4351 2.75 4.7651 2.75 5.1251V17.2051C2.75 17.7951 3.26 18.3651 3.83 18.4451L4.13 18.4851C6.38 18.7851 9.83 19.9151 11.83 21.0051C11.92 21.0451 12.05 21.0551 12.1 21.0351C14.1 19.9251 17.57 18.7851 19.83 18.4851L20.17 18.4451C20.74 18.3751 21.25 17.7951 21.25 17.2051V5.1351C21.25 4.7651 21.11 4.4451 20.86 4.2051C20.6 3.9751 20.27 3.8651 19.9 3.8851C19.88 3.8851 19.8 3.8851 19.78 3.8851C17.87 4.0551 14.79 5.0851 13.11 6.1351L12.95 6.2451C12.4 6.5851 11.62 6.5851 11.09 6.2551L10.85 6.1151C9.14 5.0651 6.06 4.0451 4.1 3.8851C4.06 3.8851 4.02 3.8851 3.98 3.8851Z"
      fill={color}
    />
    <path
      d="M12 21.7051C11.59 21.7051 11.25 21.3651 11.25 20.9551V5.95508C11.25 5.54508 11.59 5.20508 12 5.20508C12.41 5.20508 12.75 5.54508 12.75 5.95508V20.9551C12.75 21.3751 12.41 21.7051 12 21.7051Z"
      fill={color}
    />
    <path
      d="M7.75 9.70508H5.5C5.09 9.70508 4.75 9.36508 4.75 8.95508C4.75 8.54508 5.09 8.20508 5.5 8.20508H7.75C8.16 8.20508 8.5 8.54508 8.5 8.95508C8.5 9.36508 8.16 9.70508 7.75 9.70508Z"
      fill={color}
    />
    <path
      d="M8.5 12.7051H5.5C5.09 12.7051 4.75 12.3651 4.75 11.9551C4.75 11.5451 5.09 11.2051 5.5 11.2051H8.5C8.91 11.2051 9.25 11.5451 9.25 11.9551C9.25 12.3651 8.91 12.7051 8.5 12.7051Z"
      fill={color}
    />
  </svg>
);

export const IconUsers: React.FC<IconProps> = ({
  color = "currentColor",
  width = 18,
  height = 18,
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99293 13.1375C11.0671 13.1375 13.6946 13.6033 13.6946 15.4641C13.6946 17.325 11.0846 17.8041 7.99293 17.8041C4.91793 17.8041 2.29126 17.3425 2.29126 15.4808C2.29126 13.6191 4.90043 13.1375 7.99293 13.1375Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99289 10.4817C5.97456 10.4817 4.33789 8.84584 4.33789 6.82751C4.33789 4.80917 5.97456 3.17334 7.99289 3.17334C10.0104 3.17334 11.6471 4.80917 11.6471 6.82751C11.6546 8.83834 10.0296 10.4742 8.01872 10.4817H7.99289Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7358 9.53323C15.07 9.34573 16.0975 8.20073 16.1 6.8149C16.1 5.44906 15.1042 4.31573 13.7983 4.10156"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4961 12.7421C16.7886 12.9346 17.6911 13.3879 17.6911 14.3212C17.6911 14.9637 17.2661 15.3804 16.5794 15.6412"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconHolidays: React.FC<IconProps> = ({
  color = "currentColor",
  width = 16,
  height = 16,
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 -960 960 960"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M280-440h400v-80H280v80ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
      fill={color}
    />
  </svg>
);

export const IconClassPeriods: React.FC<IconProps> = ({
  color = "currentColor",
  width = 16,
  height = 16,
  style = {},
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.71521C8.41421 1.71521 8.75 2.051 8.75 2.46521V5.46521C8.75 5.87942 8.41421 6.21521 8 6.21521C7.58579 6.21521 7.25 5.87942 7.25 5.46521V2.46521C7.25 2.051 7.58579 1.71521 8 1.71521Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.71521C16.4142 1.71521 16.75 2.051 16.75 2.46521V5.46521C16.75 5.87942 16.4142 6.21521 16 6.21521C15.5858 6.21521 15.25 5.87942 15.25 5.46521V2.46521C15.25 2.051 15.5858 1.71521 16 1.71521Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 9.55518C2.75 9.14096 3.08579 8.80518 3.5 8.80518H20.5C20.9142 8.80518 21.25 9.14096 21.25 9.55518C21.25 9.96939 20.9142 10.3052 20.5 10.3052H3.5C3.08579 10.3052 2.75 9.96939 2.75 9.55518Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7371 5.85055C4.09637 6.54056 3.75 7.58018 3.75 8.96521V17.4652C3.75 18.8502 4.09637 19.8899 4.7371 20.5799C5.36848 21.2598 6.39295 21.7152 8 21.7152H16C17.607 21.7152 18.6315 21.2598 19.2629 20.5799C19.9036 19.8899 20.25 18.8502 20.25 17.4652V8.96521C20.25 7.58018 19.9036 6.54056 19.2629 5.85055C18.6315 5.17059 17.607 4.71521 16 4.71521H8C6.39295 4.71521 5.36848 5.17059 4.7371 5.85055ZM3.6379 4.82987C4.63152 3.75983 6.10705 3.21521 8 3.21521H16C17.893 3.21521 19.3685 3.75983 20.3621 4.82987C21.3464 5.88986 21.75 7.35024 21.75 8.96521V17.4652C21.75 19.0802 21.3464 20.5406 20.3621 21.6005C19.3685 22.6706 17.893 23.2152 16 23.2152H8C6.10705 23.2152 4.63152 22.6706 3.6379 21.6005C2.65363 20.5406 2.25 19.0802 2.25 17.4652V8.96521C2.25 7.35024 2.65363 5.88986 3.6379 4.82987Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6948 14.1653C14.6948 13.613 15.1425 13.1653 15.6948 13.1653H15.7038C16.2561 13.1653 16.7038 13.613 16.7038 14.1653C16.7038 14.7176 16.2561 15.1653 15.7038 15.1653H15.6948C15.1425 15.1653 14.6948 14.7176 14.6948 14.1653Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6948 17.1653C14.6948 16.613 15.1425 16.1653 15.6948 16.1653H15.7038C16.2561 16.1653 16.7038 16.613 16.7038 17.1653C16.7038 17.7176 16.2561 18.1653 15.7038 18.1653H15.6948C15.1425 18.1653 14.6948 17.7176 14.6948 17.1653Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9956 14.1653C10.9956 13.613 11.4433 13.1653 11.9956 13.1653H12.0046C12.5569 13.1653 13.0046 13.613 13.0046 14.1653C13.0046 14.7176 12.5569 15.1653 12.0046 15.1653H11.9956C11.4433 15.1653 10.9956 14.7176 10.9956 14.1653Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9956 17.1653C10.9956 16.613 11.4433 16.1653 11.9956 16.1653H12.0046C12.5569 16.1653 13.0046 16.613 13.0046 17.1653C13.0046 17.7176 12.5569 18.1653 12.0046 18.1653H11.9956C11.4433 18.1653 10.9956 17.7176 10.9956 17.1653Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29443 14.1653C7.29443 13.613 7.74215 13.1653 8.29443 13.1653H8.30342C8.8557 13.1653 9.30342 13.613 9.30342 14.1653C9.30342 14.7176 8.8557 15.1653 8.30342 15.1653H8.29443C7.74215 15.1653 7.29443 14.7176 7.29443 14.1653Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29443 17.1653C7.29443 16.613 7.74215 16.1653 8.29443 16.1653H8.30342C8.8557 16.1653 9.30342 16.613 9.30342 17.1653C9.30342 17.7176 8.8557 18.1653 8.30342 18.1653H8.29443C7.74215 18.1653 7.29443 17.7176 7.29443 17.1653Z"
      fill={color}
    />
  </svg>
);
