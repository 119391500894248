import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { PageHeader, useSelect } from "@refinedev/antd";
import { HttpError, useTranslate } from "@refinedev/core";
import {
  AutoComplete,
  Button,
  Col,
  Input,
  Row,
  Select,
  SelectProps,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";

import { DatePicker } from "components/datepicker";
import WithLabel from "components/withLabel";
import { API_RESOURCES, DATE_FORMAT } from "config";
import { IClassPeriod, ITimeTable, IUser } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { formatClassPeriod, formatUser } from "utils/modelHelper";

interface AddTimeTableContentProps {
  upsertTimeTableValues: ITimeTable | null;
  setUpsertTimeTableValues: (upsertTimeTableValues: ITimeTable | null) => void;
}

const AddTimeTableContent: React.FC<AddTimeTableContentProps> = ({
  upsertTimeTableValues,
  setUpsertTimeTableValues,
}) => {
  const t = useTranslate();

  const {
    selectProps: {
      showSearch: showSearchClassPeriod,
      onSearch: onSearchClassPeriod,
      ...classPeriodSelectProps
    },
    queryResult: classPeriodQueryResult,
  } = useSelect<IClassPeriod, HttpError>({
    resource: API_RESOURCES.classPeriods,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
  });

  const classPeriodSelectOptions = useMemo(
    () =>
      classPeriodQueryResult?.data?.data.map((classPeriod) => ({
        value: classPeriod?.id,
        label: formatClassPeriod(classPeriod),
      })),
    [classPeriodQueryResult?.data?.data]
  );

  const {
    selectProps: {
      showSearch: showSearchTeacher,
      onSearch: onSearchTeacher,
      ...teacherSelectProps
    },
    queryResult: teacherQueryResult,
  } = useSelect<IUser, HttpError>({
    resource: `${API_RESOURCES.users}/teachers`,
    optionLabel: "fullName",
    pagination: false,
  });

  const teacherSelectOptions = useMemo(
    () =>
      teacherQueryResult?.data?.data
        .sort((a, b) => (a.fullName ?? "").localeCompare(b.fullName ?? ""))
        .map((teacher) => ({
          value: teacher?.id,
          label: formatUser(teacher),
        })),
    [teacherQueryResult?.data?.data]
  );

  const { queryResult: studentQueryResult } = useSelect<IUser, HttpError>({
    resource: `${API_RESOURCES.users}/extra_students`,
    optionLabel: "fullName",
    pagination: false,
  });

  const studentList = useMemo(() => {
    return studentQueryResult?.data?.data?.filter((student) =>
      upsertTimeTableValues?.students?.find((it) => it.id === student.id)
    );
  }, [studentQueryResult?.data?.data, upsertTimeTableValues?.students]);

  const availableStudentList = useMemo(() => {
    const studentData = studentQueryResult?.data?.data.sort((a, b) =>
      (a.fullName ?? "").localeCompare(b.fullName ?? "")
    );

    if (!upsertTimeTableValues?.students?.length) {
      return studentData;
    }

    return studentData?.filter(
      (student) =>
        !upsertTimeTableValues?.students?.find((it) => it.id === student.id)
    );
  }, [studentQueryResult?.data?.data, upsertTimeTableValues?.students]);

  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [keyword, setKeyword] = useState<string>("");

  const handleSearch = useCallback(
    (value: string) => {
      if (!value) {
        setOptions(
          availableStudentList?.map((student) => ({
            value: student?.id,
            label: formatUser(student),
          }))
        );
        return;
      }

      setOptions(
        availableStudentList
          ?.filter((student) =>
            fuzzySearch(
              toLowerCaseNonAccentVietnamese(String(student.fullName ?? "")),
              toLowerCaseNonAccentVietnamese(value)
            )
          )
          ?.map((student) => ({
            value: student?.id,
            label: formatUser(student),
          }))
      );
    },
    [availableStudentList]
  );

  useEffect(() => handleSearch(""), [handleSearch]);

  const onSelect = useCallback(
    (value: string) => {
      setKeyword("");

      const foundStudent = availableStudentList?.find((it) => it.id === value);
      if (foundStudent) {
        setUpsertTimeTableValues({
          ...upsertTimeTableValues,
          students: Array.isArray(upsertTimeTableValues?.students)
            ? upsertTimeTableValues?.students.concat(foundStudent)
            : [foundStudent],
        });
      }
    },
    [availableStudentList, upsertTimeTableValues, setUpsertTimeTableValues]
  );

  const onRemoveStudent = useCallback(
    (student: IUser) => {
      setUpsertTimeTableValues({
        ...upsertTimeTableValues,
        students: Array.isArray(upsertTimeTableValues?.students)
          ? upsertTimeTableValues?.students.filter((st) => st.id !== student.id)
          : upsertTimeTableValues?.students,
      });
    },
    [upsertTimeTableValues, setUpsertTimeTableValues]
  );

  return (
    <>
      <Row gutter={[16, 24]} style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Col xs={24} sm={12} md={8}>
          <WithLabel
            label={t("extra_class.timeTables.fields.date")}
            labelStyles={{ color: "#000000D9" }}
            hasRequiredMark
          >
            <DatePicker
              placeholder={t("extra_class.timeTables.placeholders.date")}
              style={{ width: "100%" }}
              format={DATE_FORMAT}
              disabledDate={(date) => date < dayjs(new Date()).startOf("days")}
              value={
                upsertTimeTableValues?.date
                  ? dayjs(new Date(upsertTimeTableValues?.date))
                  : undefined
              }
              onChange={(date) =>
                setUpsertTimeTableValues({
                  ...upsertTimeTableValues,
                  date: date?.toISOString(),
                })
              }
            />
          </WithLabel>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <WithLabel
            label={t("extra_class.timeTables.fields.classPeriod")}
            labelStyles={{ color: "#000000D9" }}
            hasRequiredMark
          >
            <Select
              style={{ width: "100%" }}
              allowClear
              labelInValue
              placeholder={t("extra_class.timeTables.placeholders.classPeriod")}
              {...classPeriodSelectProps}
              options={classPeriodSelectOptions}
              showSearch
              filterOption={(input, option) =>
                fuzzySearch(
                  toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                  toLowerCaseNonAccentVietnamese(input)
                )
              }
              value={
                upsertTimeTableValues && upsertTimeTableValues?.classPeriod
                  ? {
                      value: upsertTimeTableValues?.classPeriod?.id ?? "",
                      label: upsertTimeTableValues?.classPeriod?.name ?? "",
                    }
                  : undefined
              }
              onChange={(item) => {
                setUpsertTimeTableValues({
                  ...upsertTimeTableValues,
                  classPeriod: item
                    ? {
                        id: item.value,
                        name: item.label,
                      }
                    : undefined,
                });
              }}
            />
          </WithLabel>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <WithLabel
            label={t("extra_class.timeTables.fields.teacher")}
            labelStyles={{ color: "#000000D9" }}
            hasRequiredMark
          >
            <Select
              style={{ width: "100%" }}
              allowClear
              labelInValue
              placeholder={t("extra_class.timeTables.placeholders.teacher")}
              {...teacherSelectProps}
              options={teacherSelectOptions}
              showSearch
              filterOption={(input, option) =>
                fuzzySearch(
                  toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                  toLowerCaseNonAccentVietnamese(input)
                )
              }
              value={
                upsertTimeTableValues && upsertTimeTableValues?.teachers?.length
                  ? {
                      value: upsertTimeTableValues?.teachers?.[0].id ?? "",
                      label:
                        upsertTimeTableValues?.teachers?.[0].fullName ?? "",
                    }
                  : undefined
              }
              onChange={(item) => {
                setUpsertTimeTableValues({
                  ...upsertTimeTableValues,
                  teachers: item
                    ? [
                        {
                          id: item.value,
                          fullName: item.label,
                        },
                      ]
                    : undefined,
                });
              }}
            />
          </WithLabel>
        </Col>
      </Row>

      <PageHeader
        backIcon={null}
        style={{ marginTop: 16, padding: 0, paddingLeft: 0, paddingRight: 0 }}
        title={
          <Typography.Text
            style={{
              fontSize: 15,
              fontWeight: 500,
            }}
          >
            {t("extra_class.timeTables.participants.title")}
          </Typography.Text>
        }
        extra={[
          <AutoComplete
            key="autocomplete"
            style={{ width: 260 }}
            options={options}
            onSelect={onSelect}
            onSearch={handleSearch}
            value={keyword}
            onChange={setKeyword}
          >
            <Input
              placeholder={t(
                "extra_class.timeTables.participants.add_students"
              )}
              prefix={<SearchOutlined />}
              allowClear
            />
          </AutoComplete>,
        ]}
      />

      <Table
        rowKey="id"
        dataSource={studentList}
        scroll={{ x: 300 }}
        loading={
          studentQueryResult?.isFetching ||
          studentQueryResult?.isLoading ||
          studentQueryResult?.isRefetching
        }
      >
        <Table.Column
          dataIndex="key"
          key="key"
          title={t("extra_class.timeTables.participants.fields.idx")}
          render={(_, record, index) => {
            return index + 1;
          }}
        />
        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title={t("extra_class.timeTables.participants.fields.fullName")}
        />
        <Table.Column
          dataIndex="username"
          key="username"
          title={t("extra_class.timeTables.participants.fields.username")}
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title={t("extra_class.timeTables.participants.fields.email")}
        />
        <Table.Column
          dataIndex="classCode"
          key="classCode"
          title={t("extra_class.timeTables.participants.fields.className")}
        />
        <Table.Column
          dataIndex="teacher"
          key="teacher"
          title={t("extra_class.timeTables.participants.fields.teacher")}
          render={(teacher: string) => teacher}
        />
        <Table.Column
          dataIndex="actions"
          key="actions"
          title={t("extra_class.timeTables.participants.fields.actions")}
          render={(_, record: IUser) => {
            return (
              <Button
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onRemoveStudent(record)}
              />
            );
          }}
        />
      </Table>
    </>
  );
};

export default AddTimeTableContent;
