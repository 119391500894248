import { AuthBindings } from "@refinedev/core";
import i18next from "i18next";

import axiosInstance from "axiosInstance";
import {
  ACCESS_TOKEN_KEY,
  HOST_API,
  PATHS,
  REFRESH_TOKEN_KEY,
  USER_AVATAR_KEY,
  USER_ID_KEY,
  USER_NAME_KEY,
  USER_ROLE_KEY,
  USER_USERNAME_KEY,
} from "config";
import { getUserRoles, removeAllUserIdentities } from "utils/identityHelper";

export const authProvider: AuthBindings = {
  login: async ({ username, password }) => {
    try {
      const { data } = await axiosInstance.post(
        `${HOST_API}/users/authenticate`,
        {
          username,
          password,
        }
      );

      localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken || "");
      localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken || "");
      localStorage.setItem(USER_ROLE_KEY, JSON.stringify(data.role || []));
      localStorage.setItem(USER_USERNAME_KEY, username || "");
      // localStorage.setItem(USER_ID_KEY, data.user?.id || "");
      // localStorage.setItem(USER_NAME_KEY, data.user?.name || "");
      // localStorage.setItem(USER_AVATAR_KEY, data.user?.avatar || "");

      return {
        success: true,
        redirectTo: PATHS.splash,
      };
    } catch (error: any) {
      return {
        success: false,
        error: {
          name:
            error.response?.data?.message ||
            i18next.t("common.something_went_wrong"),
          message: i18next.t("common.login_failed"),
        },
      };
    }
  },
  logout: async () => {
    try {
      await axiosInstance.post(`${HOST_API}/users/logout`);
    } catch (error) {
      console.log("logout error", error);
    } finally {
      removeAllUserIdentities();
      return {
        success: true,
        redirectTo: PATHS.login,
      };
    }
  },
  check: async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: PATHS.login,
    };
  },
  getPermissions: async () => Promise.resolve(),
  getIdentity: async () => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: localStorage.getItem(USER_ID_KEY) || "",
      name: localStorage.getItem(USER_NAME_KEY) || "",
      username: localStorage.getItem(USER_USERNAME_KEY) || "",
      avatar: localStorage.getItem(USER_AVATAR_KEY) || "",
      roles: getUserRoles(),
    });
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
