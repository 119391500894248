import { IUserRoleCode } from "interfaces";

export const DEFAULT_LOCALE = "vi";

export const SITE_TITLE = "JMT-EduHub";
export const SITE_DESCRIPTION = "JMT-EduHub";
export const SITE_CANONICAL = `${window.location.origin}/`;
export const SITE_THEME_COLOR = "#1677FF";

export const HOST_API = process.env.REACT_APP_HOST_API || "";

export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN_KEY";
export const REFRESH_TOKEN_KEY = "REFRESH_TOKEN_KEY";
export const USER_ID_KEY = "USER_ID_KEY";
export const USER_NAME_KEY = "USER_NAME_KEY";
export const USER_USERNAME_KEY = "USER_USERNAME_KEY";
export const USER_AVATAR_KEY = "USER_AVATAR_KEY";
export const USER_ROLE_KEY = "USER_ROLE_KEY";

export const PASSWORD_PLACEHOLDER = "●●●●●●●●";

export const DEFAULT_SIDER_WIDTH = 272;
export const DEFAULT_SIDER_COLLAPSED_WIDTH = 80;
export const DEFAULT_SIDER_DARK_BG_COLOR = "#0E172D";

export const UNAUTHEN_SIDER_WIDTH = 800;
export const UNAUTHEN_SIDER_COLLAPSED_WIDTH = 80;

export const SIDER_HEADER_HEIGHT = 140;
export const SIDER_HEADER_COLLAPSED_HEIGHT = 100;

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_2 = "YYYY-MM-DD";

export const DEFAULT_INPUT_MAX_LENGTH = 50;
export const DEFAULT_TEXT_AREA_MAX_LENGTH = 300;

export const DEFAULT_MINUTE_STEP = 5;

export const PATHS = {
  home: "/",
  forgotPassword: "/forgot-password",
  updatePassword: "/update-password",
  login: "/login",
  accountMe: "/account/me",
  dashboard: "/dashboard",
  splash: "/splash",
  notFound: "/404",
};

export const DEFAULT_ALL_FILTER_VALUE = "ALL";

export const ROLE_LIST = (
  t?: Function
): {
  value: IUserRoleCode;
  label: string;
  color: string;
  isCreatable?: boolean;
}[] => {
  return [
    {
      value: "ADMIN",
      label: t ? t("roles.admin") : "Admin",
      color: "#f50",
      isCreatable: false,
    },
    {
      value: "MANAGER",
      label: t ? t("roles.manager") : "Quản lý",
      color: "#108ee9",
      isCreatable: true,
    },
    {
      value: "TEACHER",
      label: t ? t("roles.teacher") : "Giáo viên",
      color: "#2db7f5",
      isCreatable: true,
    },
    {
      value: "STUDENT",
      label: t ? t("roles.student") : "Học sinh",
      color: "#87d068",
      isCreatable: true,
    },
  ];
};

export const API_RESOURCES = {
  dashboard: "dashboard",
  // accounts: "accounts",
  users: "users",
  // roles: "roles",
  classPeriods: "class_period",
  normalClasses: "normal_class",
  extraClasses: "extra_class",
  holidays: "holidays",
  attendances: "attendances",
  attendances_shift: "attendances_shift",
  attendances_list: "attendances_list",
  attendances_calendar: "attendances_calendar",
  notifications: "notifications",
  reports: "reports",
  reports_normal_classes: "reports_normal_classes",
  reports_extra_classes: "reports_extra_classes",
  reports_students: "reports_students",
};

export const ROLE_BASED_RESOURCES: Record<IUserRoleCode, string[]> = {
  ADMIN: [
    API_RESOURCES.reports_normal_classes,
    API_RESOURCES.reports_extra_classes,
    API_RESOURCES.reports_students,
    API_RESOURCES.reports,
    API_RESOURCES.normalClasses,
    API_RESOURCES.extraClasses,
    API_RESOURCES.classPeriods,
    API_RESOURCES.holidays,
    API_RESOURCES.users,
    // API_RESOURCES.roles,
    // API_RESOURCES.accounts,
    API_RESOURCES.attendances,
    API_RESOURCES.attendances_shift,
    API_RESOURCES.attendances_list,
    API_RESOURCES.attendances_calendar,
    API_RESOURCES.notifications,
  ],
  MANAGER: [
    API_RESOURCES.reports_normal_classes,
    API_RESOURCES.reports_extra_classes,
    API_RESOURCES.reports_students,
    API_RESOURCES.reports,
    API_RESOURCES.normalClasses,
    API_RESOURCES.extraClasses,
    API_RESOURCES.classPeriods,
    API_RESOURCES.users,
    // API_RESOURCES.roles,
    // API_RESOURCES.accounts,
    API_RESOURCES.holidays,
    API_RESOURCES.attendances,
    API_RESOURCES.attendances_shift,
    API_RESOURCES.attendances_list,
    API_RESOURCES.attendances_calendar,
    API_RESOURCES.notifications,
  ],
  TEACHER: [
    API_RESOURCES.reports_normal_classes,
    API_RESOURCES.reports_extra_classes,
    API_RESOURCES.reports_students,
    API_RESOURCES.reports,
    API_RESOURCES.normalClasses,
    API_RESOURCES.extraClasses,
    API_RESOURCES.classPeriods,
    API_RESOURCES.holidays,
    // API_RESOURCES.users,
    // API_RESOURCES.roles,
    // API_RESOURCES.accounts,
    // API_RESOURCES.attendances,
    // API_RESOURCES.attendances_shift,
    // API_RESOURCES.attendances_list,
    // API_RESOURCES.attendances_calendar,
    // API_RESOURCES.notifications,
  ],
  STUDENT: [
    // API_RESOURCES.reports_normal_classes,
    // API_RESOURCES.reports_extra_classes,
    // API_RESOURCES.reports_students,
    // API_RESOURCES.reports,
    // API_RESOURCES.normalClasses,
    // API_RESOURCES.extraClasses,
    // API_RESOURCES.classPeriods,
    // API_RESOURCES.holidays,
    // API_RESOURCES.users,
    // API_RESOURCES.roles,
    // API_RESOURCES.accounts,
    // API_RESOURCES.attendances,
    // API_RESOURCES.attendances_shift,
    // API_RESOURCES.attendances_list,
    // API_RESOURCES.attendances_calendar,
    // API_RESOURCES.notifications,
  ],
};
