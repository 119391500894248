import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import React from "react";

import { DatePicker } from "components/datepicker";
import {
  API_RESOURCES,
  DATE_FORMAT_2,
  DEFAULT_INPUT_MAX_LENGTH,
  DEFAULT_TEXT_AREA_MAX_LENGTH,
} from "config";
import { IClassPeriod, IHoliday } from "interfaces";

export const HolidayEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();
  const { list } = useNavigation();

  const {
    formProps: { onFinish: _, ...restFormProps },
    saveButtonProps,
    queryResult,
  } = useForm<IClassPeriod, HttpError>({
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("holidays.notifications.editError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("holidays.notifications.editSuccess"),
    },
  });

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  const apiUrl = useApiUrl();
  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: updateClassPeriod } = useCustomMutation<IHoliday>();

  const handleUpdateHoliday = async (values: IHoliday) => {
    updateClassPeriod(
      {
        url: `${apiUrl}/holidays/update`,
        method: "put",
        values: {
          ...values,
          id: queryResult?.data?.data?.id,
          date: values?.date
            ? dayjs(values?.date).format(DATE_FORMAT_2)
            : undefined,
          description: values?.description ?? "",
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("holidays.notifications.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("holidays.notifications.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.holidays);
          }, 100);
        },
      }
    );
  };

  return (
    <Edit
      isLoading={isProcessing}
      headerButtons={
        <>
          <ShowButton />
        </>
      }
      footerButtons={
        <>
          {resource?.meta?.canDelete && (
            <DeleteButton
              disabled={isProcessing}
              onSuccess={() => {
                list(API_RESOURCES.holidays);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("holidays.notifications.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("holidays.notifications.deleteSuccess"),
              }}
            />
          )}
          <SaveButton disabled={isProcessing} {...saveButtonProps} />
        </>
      }
    >
      <Form
        {...restFormProps}
        layout="vertical"
        initialValues={{
          ...restFormProps.initialValues,
          date: restFormProps.initialValues?.date
            ? dayjs(restFormProps.initialValues?.date)
            : undefined,
        }}
        onFinish={(values) => handleUpdateHoliday(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("holidays.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("holidays.errors.nameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("holidays.placeholders.name")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("holidays.fields.date")}
              name={["date"]}
              rules={[
                {
                  required: true,
                  message: t("holidays.errors.dateRequired"),
                },
              ]}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : undefined,
              })}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("holidays.placeholders.date")}
                disabledDate={(date) => date <= dayjs(new Date()).endOf("days")}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("holidays.fields.description")}
              name={["description"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("holidays.errors.timeRequired"),
              //   },
              // ]}
            >
              <Input.TextArea
                rows={4}
                placeholder={t("holidays.placeholders.description")}
                maxLength={DEFAULT_TEXT_AREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
