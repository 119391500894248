import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { PageHeader, useModal } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import {
  App,
  Button,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { DATE_FORMAT } from "config";
import { IClassPeriod, ITimeTable, ITimeTableStatus, IUser } from "interfaces";
import AddTimeTableContent from "./addTimeTableContent";
import ShowTimeTableContent from "./showTimeTableContent";
import {
  formatClassPeriod,
  formatUser,
  isSameTimeTable,
} from "utils/modelHelper";
import useUserRole from "hooks/useUserRole";
import HighlightTimetableStatus from "components/highlightTimetableStatus";

interface TimeTableTableProps {
  timeTableList: ITimeTable[];
  onTimeTableListChanged?: (list: ITimeTable[]) => void;
  hasAdd?: boolean;
  hasEdit?: boolean;
  hasDelete?: boolean;
  hasClone?: boolean;
  hasShow?: boolean;
}

export const TimeTableTable: React.FC<TimeTableTableProps> = ({
  timeTableList = [],
  onTimeTableListChanged,
  hasAdd,
  hasEdit,
  hasDelete,
  hasClone,
  hasShow,
}) => {
  const { hasAdminRole, hasManagerRole } = useUserRole();
  const canShowUser = hasAdminRole || hasManagerRole;

  const t = useTranslate();
  const { notification } = App.useApp();

  const [isFilter, setIsFilter] = useState(false);

  const filteredTimeTableList = useMemo(() => {
    if (isFilter) {
      return timeTableList?.filter((it) => it.status !== 3);
    }
    return timeTableList;
  }, [isFilter, timeTableList]);

  const [upsertTimeTableValues, setUpsertTimeTableValues] =
    useState<ITimeTable | null>(null);

  const {
    modalProps: modalAddTimeTableProps,
    show: showAddTimeTable,
    close: closeAddTimeTable,
  } = useModal();

  const addOrEditTimeTable = async (timeTable: ITimeTable) => {
    const { key, ...rest } = timeTable;

    if (timeTableList.find((tt) => isSameTimeTable(tt, timeTable))) {
      notification.warning({
        message: t("extra_class.notifications.duplicateTimeTable"),
      });
      return;
    }

    const newTimeTableList = !key
      ? timeTableList.concat({
          key: timeTableList.length + 1,
          ...rest,
        })
      : timeTableList.map((timeTable) => {
          if (timeTable.key === key) {
            return {
              key,
              ...rest,
            };
          }
          return timeTable;
        });

    closeAddTimeTable();
    setUpsertTimeTableValues(null);
    onTimeTableListChanged?.(newTimeTableList);
  };

  const deleteTimeTable = async (record: ITimeTable) => {
    const newTimeTableList = timeTableList
      .filter((timeTable) => timeTable.key !== record.key)
      .map((timeTable, index) => ({
        ...timeTable,
        key: index + 1,
      }));

    onTimeTableListChanged?.(newTimeTableList);
  };

  const [modalType, setModalType] = useState<
    "EDIT" | "CREATE" | "CLONE" | "VIEW" | null
  >(null);

  const onEdit = (record: ITimeTable) => {
    setModalType("EDIT");
    showAddTimeTable();
    setTimeout(() => setUpsertTimeTableValues({ ...record }), 0);
  };

  const onView = (record: ITimeTable) => {
    setModalType("VIEW");
    showAddTimeTable();
    setTimeout(() => setUpsertTimeTableValues({ ...record }), 0);
  };

  const onClone = (record: ITimeTable) => {
    setModalType("CLONE");
    showAddTimeTable();
    setTimeout(
      () =>
        setUpsertTimeTableValues({
          ...record,
          key: undefined,
          date: undefined,
          status: undefined,
        }),
      0
    );
  };

  const onAdd = () => {
    setModalType("CREATE");
    showAddTimeTable();
  };

  const onDelete = (record: ITimeTable) => {
    deleteTimeTable(record);
  };

  const modalEnableOk = () => {
    return (
      upsertTimeTableValues &&
      upsertTimeTableValues.date &&
      upsertTimeTableValues?.classPeriod &&
      upsertTimeTableValues?.students &&
      upsertTimeTableValues?.students.length &&
      upsertTimeTableValues?.teachers &&
      upsertTimeTableValues?.teachers?.length
    );
  };

  return (
    <>
      <PageHeader
        backIcon={null}
        style={{ padding: 0 }}
        title={
          <Typography.Text
            style={{
              fontSize: 16,
            }}
          >
            {t("extra_class.timeTables.title")}
          </Typography.Text>
        }
        extra={
          <Space size="middle">
            <Switch
              key="filter"
              checked={isFilter}
              onChange={(checked) => setIsFilter(checked)}
            />
            {hasAdd && (
              <Button
                key="add-timeTable"
                type="primary"
                icon={<PlusOutlined />}
                onClick={onAdd}
              >
                {t("extra_class.timeTables.buttons.add")}
              </Button>
            )}
          </Space>
        }
      />

      <Table
        rowKey="key"
        dataSource={filteredTimeTableList}
        scroll={{ x: 300 }}
      >
        <Table.Column<ITimeTable>
          dataIndex="key"
          key="key"
          title={t("extra_class.timeTables.fields.idx")}
          render={(_, record, index) => {
            return index + 1;
          }}
        />

        <Table.Column
          dataIndex={"date"}
          key="date"
          title={t("extra_class.timeTables.fields.date")}
          render={(date) => (date ? dayjs(date).format(DATE_FORMAT) : "")}
        />

        <Table.Column
          dataIndex={"classPeriod"}
          key="classPeriod"
          title={t("extra_class.timeTables.fields.classPeriod")}
          render={(classPeriod: IClassPeriod) => {
            if (!classPeriod.startTime && !classPeriod.endTime) {
              return classPeriod?.name;
            }
            return formatClassPeriod(classPeriod);
          }}
        />

        <Table.Column<ITimeTable>
          dataIndex="teachers"
          key="teachers"
          title={t("extra_class.timeTables.fields.teacher")}
          render={(teachers: IUser[]) => {
            return (
              <Space direction="vertical">
                {teachers?.map((teacher) => {
                  const displayname = !teacher.username
                    ? teacher.fullName
                    : formatUser(teacher);
                  return canShowUser ? (
                    <Link
                      key={teacher.id}
                      to={`/users/show/${teacher.id}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {displayname}
                    </Link>
                  ) : (
                    <>{displayname}</>
                  );
                })}
              </Space>
            );
          }}
        />

        <Table.Column
          dataIndex="status"
          key="status"
          title={t("extra_class.timeTables.fields.status")}
          render={(status: ITimeTableStatus) => {
            return <HighlightTimetableStatus status={status} />;
          }}
        />

        {(hasEdit || hasDelete || hasClone || hasShow) && (
          <Table.Column<ITimeTable>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {hasClone && (
                  <Button
                    icon={<CopyOutlined />}
                    size="small"
                    onClick={() => onClone(record)}
                  />
                )}
                {hasEdit && (
                  <Button
                    disabled={record?.status !== 1}
                    icon={<EditOutlined />}
                    size="small"
                    onClick={() => onEdit(record)}
                  />
                )}
                {hasDelete && (
                  <Popconfirm
                    title={t("buttons.confirm")}
                    onConfirm={() => onDelete(record)}
                    onCancel={() => null}
                    okText={t("buttons.clear")}
                    cancelText={t("buttons.cancel")}
                    okType="danger"
                    disabled={record?.status !== 1}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      danger
                      size="small"
                      disabled={record?.status !== 1}
                    />
                  </Popconfirm>
                )}
                {hasShow && (
                  <Button
                    icon={<EyeOutlined />}
                    size="small"
                    onClick={() => onView(record)}
                  />
                )}
              </Space>
            )}
          />
        )}
      </Table>

      <Modal
        {...modalAddTimeTableProps}
        width={900}
        title={
          modalType === "EDIT" ? (
            <Space>
              {t("extra_class.timeTables.modalEditTitle")}
              <HighlightTimetableStatus
                status={upsertTimeTableValues?.status}
              />
            </Space>
          ) : modalType === "CREATE" || modalType === "CLONE" ? (
            t("extra_class.timeTables.modalTitle")
          ) : (
            <Space>
              {t("extra_class.timeTables.modalShowTitle")}
              <HighlightTimetableStatus
                status={upsertTimeTableValues?.status}
              />
            </Space>
          )
        }
        cancelButtonProps={{
          ghost: modalType === "VIEW",
        }}
        okButtonProps={{
          disabled: !modalEnableOk(),
        }}
        onCancel={() => {
          setUpsertTimeTableValues(null);
          closeAddTimeTable();
        }}
        onOk={() => {
          if (modalEnableOk()) {
            addOrEditTimeTable(upsertTimeTableValues!);
          }
        }}
      >
        {modalType === "VIEW" ? (
          <ShowTimeTableContent upsertTimeTableValues={upsertTimeTableValues} />
        ) : (
          <AddTimeTableContent
            upsertTimeTableValues={upsertTimeTableValues}
            setUpsertTimeTableValues={setUpsertTimeTableValues}
          />
        )}
      </Modal>
    </>
  );
};
