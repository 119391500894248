import dayjs from "dayjs";

import { IClassPeriod, ITimeTable, IUser } from "interfaces";
import { formatTimePeriodString } from "./timeHelpers";
import { DATE_FORMAT } from "config";

export function formatUser(user?: IUser) {
  return user ? `${user?.fullName} (${user?.username})` : "";
}

export function formatClassPeriod(classPeriod?: IClassPeriod) {
  return classPeriod
    ? `${classPeriod.name} (${formatTimePeriodString(
        classPeriod.startTime
      )} - ${formatTimePeriodString(classPeriod.endTime)})`
    : "";
}

export function formatSchoolYear(schoolYear?: string | number) {
  if (!schoolYear) return "";

  const schoolYearNumber =
    typeof schoolYear === "string" ? parseInt(schoolYear) : schoolYear;

  if (Number.isNaN(schoolYear)) {
    return "";
  }

  return `${schoolYearNumber} - ${schoolYearNumber + 1}`;
}

export function isSameTimeTable(
  timetable1: ITimeTable,
  timetable2: ITimeTable
) {
  const date1 = timetable1.date
    ? dayjs(timetable1.date).format(DATE_FORMAT)
    : "";
  const date2 = timetable2.date
    ? dayjs(timetable2.date).format(DATE_FORMAT)
    : "";
  return (
    date1 === date2 &&
    timetable1?.classPeriod?.id === timetable2?.classPeriod?.id
  );
}
