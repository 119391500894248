import { Create, useForm } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import React from "react";

import { DatePicker } from "components/datepicker";
import {
  API_RESOURCES,
  DATE_FORMAT_2,
  DEFAULT_INPUT_MAX_LENGTH,
  DEFAULT_TEXT_AREA_MAX_LENGTH,
} from "config";
import { IHoliday } from "interfaces";

export const HolidayCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    formProps: { onFinish: _, ...restFormProps },
    saveButtonProps,
  } = useForm<IHoliday, HttpError>({
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("holidays.notifications.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("holidays.notifications.createSuccess"),
    },
  });

  const { list } = useNavigation();
  const apiUrl = useApiUrl();
  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: createClassPeriod } = useCustomMutation<IHoliday>();

  const handleCreateClassPeriod = async (values: IHoliday) => {
    createClassPeriod(
      {
        url: `${apiUrl}/holidays/create`,
        method: "post",
        values: {
          ...values,
          date: values?.date
            ? dayjs(values?.date).format(DATE_FORMAT_2)
            : undefined,
          description: values?.description ?? "",
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("holidays.notifications.createError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("holidays.notifications.createSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.holidays);
          }, 100);
        },
      }
    );
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...restFormProps}
        layout="vertical"
        onFinish={(values) => handleCreateClassPeriod(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("holidays.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("holidays.errors.nameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("holidays.placeholders.name")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("holidays.fields.date")}
              name={["date"]}
              rules={[
                {
                  required: true,
                  message: t("holidays.errors.dateRequired"),
                },
              ]}
              getValueProps={(value) => ({
                value: value ? dayjs(value) : undefined,
              })}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("holidays.placeholders.date")}
                disabledDate={(date) => date <= dayjs(new Date()).endOf("days")}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("holidays.fields.description")}
              name={["description"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("holidays.errors.timeRequired"),
              //   },
              // ]}
            >
              <Input.TextArea
                rows={4}
                placeholder={t("holidays.placeholders.description")}
                maxLength={DEFAULT_TEXT_AREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
