import { ErrorComponent, notificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import {
  Authenticated,
  // GitHubBanner,
  Refine,
} from "@refinedev/core";
import { RefineKbarProvider } from "@refinedev/kbar";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { App as AntApp } from "antd";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import axiosInstance from "axiosInstance";
import {
  IconClassPeriods,
  IconExtraClasses,
  IconHolidays,
  IconNormalClasses,
  IconReport,
  IconUsers,
} from "components/icons";
import {
  API_RESOURCES,
  HOST_API,
  SITE_CANONICAL,
  SITE_DESCRIPTION,
  SITE_THEME_COLOR,
  SITE_TITLE,
} from "config";
import AccountInfo from "pages/accountInfo";
import {
  AttendanceCalendarCreate,
  AttendanceCalendarEdit,
  AttendanceCalendarList,
  AttendanceCalendarShow,
} from "pages/attendances-calendar";
import {
  AttendanceListCreate,
  AttendanceListEdit,
  AttendanceListList,
  AttendanceListShow,
} from "pages/attendances-list";
import {
  AttendanceShiftCreate,
  AttendanceShiftEdit,
  AttendanceShiftList,
  AttendanceShiftShow,
} from "pages/attendances-shift";
import {
  ClassPeriodCreate,
  ClassPeriodEdit,
  ClassPeriodList,
  ClassPeriodShow,
} from "pages/class-periods";
import {
  ExtraClassCreate,
  ExtraClassEdit,
  ExtraClassList,
  ExtraClassShow,
} from "pages/extra-classes";
import ForgotPassword from "pages/forgotPassword";
import {
  HolidayCreate,
  HolidayEdit,
  HolidayList,
  HolidayShow,
} from "pages/holidays";
import Login from "pages/login";
import {
  NormalClassCreate,
  NormalClassEdit,
  NormalClassList,
  NormalClassShow,
} from "pages/normal-classes";
import { NotificationList, NotificationShow } from "pages/notifications";
import RemoveAccount from "pages/removeAccount";
import {
  ReportExtraClassList,
  ReportExtraClassShow,
} from "pages/reports-extra-classes";
import {
  ReportNormalClassList,
  ReportNormalClassShow,
} from "pages/reports-normal-classes";
import { ReportStudentList } from "pages/reports-students";
import { ReportStudentsShow } from "pages/reports-students/show";
import Splash from "pages/splash";
import UpdatePassword from "pages/updatePassword";
import { UserCreate, UserEdit, UserList, UserShow } from "pages/users";
import { AuthenticatedLayout } from "./components/layout";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { accessControlProvider, authProvider, dataProvider } from "./providers";
import "./App.css";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <HelmetProvider>
      <Helmet>
        <html lang={i18n.language} />
        <title>{SITE_TITLE}</title>
        <meta name="description" content={SITE_DESCRIPTION} />
        <meta name="theme-color" content={SITE_THEME_COLOR} />
        <link rel="canonical" href={SITE_CANONICAL} />
      </Helmet>

      <BrowserRouter>
        {/* <GitHubBanner /> */}

        <RefineKbarProvider>
          <ColorModeContextProvider>
            <AntApp>
              <Refine
                dataProvider={dataProvider(HOST_API, axiosInstance)}
                notificationProvider={notificationProvider}
                accessControlProvider={accessControlProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                resources={[
                  {
                    name: API_RESOURCES.reports,
                    meta: {
                      icon: <IconReport style={{ marginRight: 8 }} />,
                    },
                  },
                  {
                    name: API_RESOURCES.reports_normal_classes,
                    list: "/reports_normal_classes",
                    show: "/reports_normal_classes/show/:id",
                    meta: {
                      parent: API_RESOURCES.reports,
                    },
                  },
                  {
                    name: API_RESOURCES.reports_extra_classes,
                    list: "/reports_extra_classes",
                    show: "/reports_extra_classes/show/:id",
                    meta: {
                      parent: API_RESOURCES.reports,
                    },
                  },
                  {
                    name: API_RESOURCES.reports_students,
                    list: "/reports_students",
                    show: "/reports_students/show/:id",
                    meta: {
                      parent: API_RESOURCES.reports,
                    },
                  },
                  {
                    name: API_RESOURCES.normalClasses,
                    list: "/normal_class",
                    create: "/normal_class/create",
                    edit: "/normal_class/edit/:id",
                    show: "/normal_class/show/:id",
                    meta: {
                      canDelete: true,
                      icon: <IconNormalClasses style={{ marginRight: 8 }} />,
                    },
                  },
                  {
                    name: API_RESOURCES.extraClasses,
                    list: "/extra_class",
                    create: "/extra_class/create",
                    edit: "/extra_class/edit/:id",
                    show: "/extra_class/show/:id",
                    meta: {
                      canDelete: true,
                      icon: <IconExtraClasses style={{ marginRight: 8 }} />,
                    },
                  },
                  {
                    name: API_RESOURCES.classPeriods,
                    list: "/class_period",
                    create: "/class_period/create",
                    edit: "/class_period/edit/:id",
                    show: "/class_period/show/:id",
                    meta: {
                      canDelete: true,
                      icon: <IconClassPeriods style={{ marginRight: 8 }} />,
                    },
                  },
                  // {
                  //   name: API_RESOURCES.dashboard,
                  //   list: "/dashboard",
                  //   meta: {
                  //     icon: <DashboardFilled />,
                  //   },
                  // },
                  // {
                  //   name: API_RESOURCES.accounts,
                  //   meta: {
                  //     icon: <UserOutlined />,
                  //   },
                  // },
                  {
                    name: API_RESOURCES.holidays,
                    list: "/holidays",
                    create: "/holidays/create",
                    edit: "/holidays/edit/:id",
                    show: "/holidays/show/:id",
                    meta: {
                      canDelete: true,
                      icon: <IconHolidays style={{ marginRight: 8 }} />,
                    },
                  },
                  {
                    name: API_RESOURCES.users,
                    list: "/users",
                    create: "/users/create",
                    edit: "/users/edit/:id",
                    show: "/users/show/:id",
                    meta: {
                      canDelete: true,
                      icon: <IconUsers style={{ marginRight: 8 }} />,
                      // parent: API_RESOURCES.accounts,
                    },
                  },
                  // {
                  //   name: API_RESOURCES.roles,
                  //   list: "/roles",
                  //   create: "/roles/create",
                  //   edit: "/roles/edit/:id",
                  //   show: "/roles/show/:id",
                  //   meta: {
                  //     canDelete: true,
                  //     parent: API_RESOURCES.accounts,
                  //   },
                  // },
                  // {
                  //   name: API_RESOURCES.attendances,
                  //   meta: {
                  //     icon: <UserOutlined />,
                  //   },
                  // },
                  // {
                  //   name: API_RESOURCES.attendances_shift,
                  //   list: "/attendances_shift",
                  //   create: "/attendances_shift/create",
                  //   edit: "/attendances_shift/edit/:id",
                  //   show: "/attendances_shift/show/:id",
                  //   meta: {
                  //     canDelete: true,
                  //     parent: API_RESOURCES.attendances,
                  //   },
                  // },
                  // {
                  //   name: API_RESOURCES.attendances_list,
                  //   list: "/attendances_list",
                  //   create: "/attendances_list/create",
                  //   edit: "/attendances_list/edit/:id",
                  //   show: "/attendances_list/show/:id",
                  //   meta: {
                  //     canDelete: true,
                  //     parent: API_RESOURCES.attendances,
                  //   },
                  // },
                  // {
                  //   name: API_RESOURCES.attendances_calendar,
                  //   list: "/attendances_calendar",
                  //   create: "/attendances_calendar/create",
                  //   edit: "/attendances_calendar/edit/:id",
                  //   show: "/attendances_calendar/show/:id",
                  //   meta: {
                  //     canDelete: true,
                  //     parent: API_RESOURCES.attendances,
                  //   },
                  // },
                  // {
                  //   name: API_RESOURCES.notifications,
                  //   list: "/notifications",
                  //   show: "/notifications/show/:id",
                  //   meta: {
                  //     icon: <NotificationOutlined />,
                  //   },
                  // },
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                }}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <AuthenticatedLayout>
                          <Outlet />
                        </AuthenticatedLayout>
                      </Authenticated>
                    }
                  >
                    <Route
                      index
                      element={
                        // <NavigateToResource resource={API_RESOURCES.blogPosts} />
                        <Navigate to="/splash" />
                      }
                    />
                    <Route path="/splash" element={<Splash />} />
                    <Route path="/account/me" element={<AccountInfo />} />
                    <Route
                      path="/update-password"
                      element={<UpdatePassword />}
                    />
                    {/* <Route path="/dashboard">
                    <Route index element={<Dashboard />} />
                  </Route> */}
                    <Route path="/users">
                      <Route index element={<UserList />} />
                      <Route path="create" element={<UserCreate />} />
                      <Route path="edit/:id" element={<UserEdit />} />
                      <Route path="show/:id" element={<UserShow />} />
                    </Route>
                    {/* <Route path="/roles">
                      <Route index element={<RoleList />} />
                      <Route path="create" element={<RoleCreate />} />
                      <Route path="edit/:id" element={<RoleEdit />} />
                      <Route path="show/:id" element={<RoleShow />} />
                    </Route> */}
                    <Route path="/class_period">
                      <Route index element={<ClassPeriodList />} />
                      <Route path="create" element={<ClassPeriodCreate />} />
                      <Route path="edit/:id" element={<ClassPeriodEdit />} />
                      <Route path="show/:id" element={<ClassPeriodShow />} />
                    </Route>
                    <Route path="/normal_class">
                      <Route index element={<NormalClassList />} />
                      <Route path="create" element={<NormalClassCreate />} />
                      <Route path="edit/:id" element={<NormalClassEdit />} />
                      <Route path="show/:id" element={<NormalClassShow />} />
                    </Route>
                    <Route path="/extra_class">
                      <Route index element={<ExtraClassList />} />
                      <Route path="create" element={<ExtraClassCreate />} />
                      <Route path="edit/:id" element={<ExtraClassEdit />} />
                      <Route path="show/:id" element={<ExtraClassShow />} />
                    </Route>
                    <Route path="/holidays">
                      <Route index element={<HolidayList />} />
                      <Route path="create" element={<HolidayCreate />} />
                      <Route path="edit/:id" element={<HolidayEdit />} />
                      <Route path="show/:id" element={<HolidayShow />} />
                    </Route>
                    <Route path="/attendances_shift">
                      <Route index element={<AttendanceShiftList />} />
                      <Route
                        path="create"
                        element={<AttendanceShiftCreate />}
                      />
                      <Route
                        path="edit/:id"
                        element={<AttendanceShiftEdit />}
                      />
                      <Route
                        path="show/:id"
                        element={<AttendanceShiftShow />}
                      />
                    </Route>
                    <Route path="/attendances_list">
                      <Route index element={<AttendanceListList />} />
                      <Route path="create" element={<AttendanceListCreate />} />
                      <Route path="edit/:id" element={<AttendanceListEdit />} />
                      <Route path="show/:id" element={<AttendanceListShow />} />
                    </Route>
                    <Route path="/attendances_calendar">
                      <Route index element={<AttendanceCalendarList />} />
                      <Route
                        path="create"
                        element={<AttendanceCalendarCreate />}
                      />
                      <Route
                        path="edit/:id"
                        element={<AttendanceCalendarEdit />}
                      />
                      <Route
                        path="show/:id"
                        element={<AttendanceCalendarShow />}
                      />
                    </Route>
                    <Route path="/notifications">
                      <Route index element={<NotificationList />} />
                      <Route path="show/:id" element={<NotificationShow />} />
                    </Route>
                    <Route path="/reports_normal_classes">
                      <Route index element={<ReportNormalClassList />} />
                      <Route
                        path="show/:id"
                        element={<ReportNormalClassShow />}
                      />
                    </Route>
                    <Route path="/reports_extra_classes">
                      <Route index element={<ReportExtraClassList />} />
                      <Route
                        path="show/:id"
                        element={<ReportExtraClassShow />}
                      />
                    </Route>
                    <Route path="/reports_students">
                      <Route index element={<ReportStudentList />} />
                      <Route path="show/:id" element={<ReportStudentsShow />} />
                    </Route>
                  </Route>

                  <Route
                    element={
                      <Authenticated fallback={<Outlet />}>
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="/register" element={<Register />} /> */}
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                  </Route>

                  <Route
                    element={
                      <>
                        <Outlet />
                      </>
                    }
                  >
                    <Route path="/remove-account" element={<RemoveAccount />} />
                  </Route>

                  <Route
                    element={
                      <Authenticated>
                        <AuthenticatedLayout>
                          <Outlet />
                        </AuthenticatedLayout>
                      </Authenticated>
                    }
                  >
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                </Routes>

                {/* <RefineKbar /> */}
                <UnsavedChangesNotifier />
              </Refine>
            </AntApp>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
