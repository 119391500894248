export function handleUploadFile({
  accept = ".xlsx",
  onChange = (event: Event) => {},
} = {}) {
  const input = document.createElement("input");
  input.type = "file";
  input.style.display = "none";
  input.style.width = "0px";
  input.style.height = "0px";
  input.accept = accept;
  input.addEventListener("change", onChange);
  document.body.appendChild(input);
  input.click();
  return input; // @IMPORTANT: remember to call document.body.removeChild(input) after done
}

export function downloadData(data: any, filename: string) {
  // create file link in browser's memory
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function openPublicPath(
  path: string,
  target?: string,
  features?: string
) {
  window.open(`${window.location.origin}${path}`, target, features);
}
