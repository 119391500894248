import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { Show, useModal, useTable } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { App, Button, Col, Modal, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import axiosInstance from "axiosInstance";
import HighlightTimetableStatus from "components/highlightTimetableStatus";
import TextBox from "components/textBox";
import { DATE_FORMAT } from "config";
import { IExtraClass, ITimeTableReportExtraClass } from "interfaces";
import { formatOperationTime } from "pages/extra-classes/private/util";
import { downloadData } from "utils/domHelper";
import { formatClassPeriod, formatSchoolYear } from "utils/modelHelper";
import TimeTableReportExtraClass from "./private/TimeTableReportExtraClass";

export const ReportExtraClassShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const { id } = useParams();
  const t = useTranslate();
  const { notification } = App.useApp();

  const {
    tableProps: { loading, ...restTableProps },
    tableQueryResult,
  } = useTable<ITimeTableReportExtraClass, HttpError>({
    resource: `reports/extra_class/timetable/${id}`,
    syncWithLocation: true,
    queryOptions: {
      enabled: Boolean(id),
    },
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const isTableLoading =
    tableQueryResult?.isFetching ||
    tableQueryResult?.isRefetching ||
    tableQueryResult?.isLoading;

  const [timetableReportExtraClass, setTimetableReportExtraClass] =
    useState<ITimeTableReportExtraClass | null>(null);

  const {
    modalProps: modalTimeTableReportProps,
    show: showTimeTableReport,
    close: closeTimeTableReport,
  } = useModal();

  const apiUrl = useApiUrl();
  const { data: extraClassData, isLoading: isExtraClassLoading } =
    useCustom<IExtraClass>({
      url: `${apiUrl}/extra_class/${id}`,
      method: "get",
    });
  const extraClassRecord = extraClassData?.data;

  const [isRefetchingExport, setIsRefetchingExport] = useState(false);

  const refetchExport = async () => {
    try {
      setIsRefetchingExport(true);
      const { data } = await axiosInstance.get(
        `${apiUrl}/reports/extra_class/export/${id}`,
        {
          responseType: "blob",
        }
      );
      downloadData(data, t("reports_extra_classes.exports.fileName"));
      notification.success({
        message: t("reports_extra_classes.notifications.exportSuccess"),
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.message ??
          t("reports_extra_classes.notifications.exportError"),
      });
    } finally {
      setIsRefetchingExport(false);
    }
  };

  return (
    <>
      <Show
        isLoading={isTableLoading || isExtraClassLoading}
        headerButtons={[
          <Button
            key="download"
            icon={<CloudDownloadOutlined />}
            loading={isRefetchingExport}
            disabled={isRefetchingExport}
            onClick={() => refetchExport()}
          >
            {t("buttons.export")}
          </Button>,
        ]}
      >
        <Row gutter={[16, 24]} style={{ marginBottom: 32 }}>
          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={formatSchoolYear(extraClassRecord?.schoolYear)}
              label={t("extra_class.fields.schoolYear")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={extraClassRecord?.code}
              label={t("extra_class.fields.code")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={extraClassRecord?.name}
              label={t("extra_class.fields.name")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={formatOperationTime(
                extraClassRecord?.startDate,
                extraClassRecord?.endDate
              )}
              label={t("extra_class.fields.operatingTime")}
            />
          </Col>
        </Row>

        <Typography.Title level={5} style={{ color: "#00748F" }}>
          {t("reports_extra_classes.titles.detail_list")}
        </Typography.Title>

        <Table
          {...restTableProps}
          rowKey={(record) => `${record?.date}-${record?.classPeriod?.id}`}
          bordered
        >
          <Table.Column
            dataIndex="idx"
            title={t("reports_extra_classes.fields.idx")}
          />
          <Table.Column
            dataIndex="date"
            title={t("reports_extra_classes.fields.date")}
            render={(date) => (date ? dayjs(date).format(DATE_FORMAT) : "")}
          />
          <Table.Column
            dataIndex="classPeriod"
            title={t("reports_extra_classes.fields.classPeriod")}
            render={(classPeriod) => formatClassPeriod(classPeriod)}
          />
          <Table.Column
            dataIndex="timetableStatus"
            title={t("common.status")}
            render={(timetableStatus) => (
              <HighlightTimetableStatus status={timetableStatus} />
            )}
          />
          <Table.Column
            dataIndex="totalStudent"
            title={t("reports_extra_classes.fields.totalStudent")}
          />
          <Table.Column
            dataIndex="attendedStudents"
            title={t("reports_extra_classes.fields.attendedStudents")}
            render={(attendedStudents) => attendedStudents?.length ?? ""}
          />
          <Table.ColumnGroup title={t("reports_extra_classes.fields.absented")}>
            <Table.Column
              dataIndex="absentedWithNoticeStudents"
              title={t(
                "reports_extra_classes.fields.absentedWithNoticeStudents"
              )}
              render={(absentedWithNoticeStudents) =>
                absentedWithNoticeStudents?.length ?? ""
              }
            />
            <Table.Column
              dataIndex="absentedStudents"
              title={t("reports_extra_classes.fields.absentedStudents")}
              render={(absentedStudents) => absentedStudents?.length ?? ""}
            />
            <Table.Column
              dataIndex="lateStudents"
              title={t("reports_extra_classes.fields.lateStudents")}
              render={(lateStudents) => lateStudents?.length ?? ""}
            />
            <Table.Column
              dataIndex="otherReasonStudents"
              title={t("reports_extra_classes.fields.otherReasonStudents")}
              render={(otherReasonStudents) =>
                otherReasonStudents?.length ?? ""
              }
            />
            <Table.Column
              dataIndex="notProcessStudents"
              title={t("reports_normal_classes.fields.notProcessStudents")}
              render={(notProcessStudents) => notProcessStudents?.length ?? ""}
            />
          </Table.ColumnGroup>

          <Table.Column
            title={t("reports_extra_classes.fields.actions")}
            dataIndex="actions"
            render={(_, record: ITimeTableReportExtraClass) => {
              return (
                <Space>
                  {resource?.canShow && (
                    <Button
                      size="small"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        showTimeTableReport();
                        setTimetableReportExtraClass(record);
                      }}
                    />
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Show>

      <Modal
        {...modalTimeTableReportProps}
        width={900}
        title={t("reports_extra_classes.details.title")}
        onCancel={() => {
          setTimetableReportExtraClass(null);
          closeTimeTableReport();
        }}
        footer={null}
      >
        {timetableReportExtraClass && (
          <TimeTableReportExtraClass record={timetableReportExtraClass} />
        )}
      </Modal>
    </>
  );
};
