import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useGetIdentity,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Row, Table, Tag, Typography } from "antd";
import React, { useMemo } from "react";

import TextBox from "components/textBox";
import { API_RESOURCES, ROLE_LIST } from "config";
import { IUser, IUserIdentity } from "interfaces";
import { formatSchoolYear } from "utils/modelHelper";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const { list } = useNavigation();
  const t = useTranslate();

  const { queryResult } = useShow<IUser, HttpError>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const classes = useMemo(
    () => record?.classes?.map((it, idx) => ({ idx: idx + 1, ...it })) ?? [],
    [record?.classes]
  );

  const { data: user } = useGetIdentity<IUserIdentity>();
  const isMe = user?.username === record?.username;
  const isAdmin = Boolean(record?.roles?.find((it) => it.code === "ADMIN"));

  return (
    <Show
      isLoading={isLoading}
      headerButtons={
        <>
          {resource?.canEdit && !isMe && !isAdmin && (
            <EditButton disabled={isLoading} type="primary" />
          )}
          {resource?.meta?.canDelete && !isMe && !isAdmin && (
            <DeleteButton
              disabled={isLoading}
              onSuccess={() => {
                list(API_RESOURCES.users);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("users.notifications.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("users.notifications.deleteSuccess"),
              }}
            />
          )}
        </>
      }
    >
      <Row gutter={[16, 24]} style={{ marginBottom: 32 }}>
        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.fullName ?? "--"}
            label={t("users.fields.fullName")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.username ?? "--"}
            label={t("users.fields.username")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.mobile ?? "--"}
            label={t("users.fields.mobile")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.email ?? "--"}
            label={t("users.fields.email")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={ROLE_LIST(t)
              .filter((role) =>
                record?.roles?.find((it) => it.code === role.value)
              )
              ?.map((role) => role.label)
              ?.join(", ")}
            label={t("users.fields.role")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.position ?? "--"}
            label={t("users.fields.position")}
          />
        </Col>
      </Row>

      <Typography.Title level={5}>
        {t("users.details.classes")}
      </Typography.Title>

      <Table dataSource={classes} rowKey="classId" style={{ marginBottom: 24 }}>
        <Table.Column
          dataIndex="idx"
          title={t("users.details.fields.idx")}
          width={125}
        />
        <Table.Column
          dataIndex="schoolYear"
          title={t("users.details.fields.schoolYear")}
          render={(schoolYear) => formatSchoolYear(schoolYear)}
          width={250}
        />
        <Table.Column
          dataIndex="className"
          title={t("users.details.fields.className")}
        />
        <Table.Column
          dataIndex="classCode"
          title={t("users.details.fields.classCode")}
        />
        <Table.Column
          dataIndex="classType"
          title={t("users.details.fields.classType")}
          render={(classType) => (
            <Tag color={classType === "CLASSIC" ? "success" : "processing"}>
              {t(`users.details.fields.classType_${classType}`)}
            </Tag>
          )}
        />
      </Table>
    </Show>
  );
};
