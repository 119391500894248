import {
  CreateButton,
  DateField,
  EditButton,
  MarkdownField,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useCustomMutation,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Button, Space, Table } from "antd";
import React from "react";

import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { PageList } from "components/pages";

export const AttendanceListList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useTable<BaseRecord, HttpError>({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;

  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  const apiUrl = useApiUrl();

  const { isRefetching: isRefetchingExport, refetch: refetchExport } =
    useCustom({
      url: `${apiUrl}/export/users`,
      method: "get",
      queryOptions: {
        enabled: false,
        retry: 0,
      },
      errorNotification(error: any) {
        return {
          type: "error",
          message:
            error?.message ?? t("attendances_list.notifications.exportError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("attendances_list.notifications.exportSuccess"),
      },
    });

  const { mutate: importUsers, isLoading: isImportingUsers } =
    useCustomMutation<{
      key: string;
    }>();

  const handleImportUsers = async (key?: string) => {
    importUsers({
      url: `${apiUrl}/import/users`,
      method: "post",
      values: {
        key,
      },
      errorNotification(error) {
        return {
          type: "error",
          message:
            error?.message ?? t("attendances_list.notifications.importError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("attendances_list.notifications.importSuccess"),
      },
    });
  };

  return (
    <PageList
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[
        <Button
          icon={<CloudDownloadOutlined />}
          loading={isImportingUsers}
          disabled={isImportingUsers}
          onClick={() => handleImportUsers()}
        >
          {t("buttons.import")}
        </Button>,
        <Button
          icon={<CloudUploadOutlined />}
          loading={isRefetchingExport}
          disabled={isRefetchingExport}
          onClick={() => refetchExport()}
        >
          {t("buttons.export")}
        </Button>,
        <CreateButton type="primary" icon={<PlusOutlined />}>
          {t("buttons.create")}
        </CreateButton>,
      ]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column
          dataIndex="content"
          title="Content"
          render={(value: any) => (
            <MarkdownField value={value?.slice(0, 80) + "..."} />
          )}
        />
        <Table.Column dataIndex="status" title="Status" />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </PageList>
  );
};
