import { EditOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Card, Col, Row, Space, Typography } from "antd";
import { UserAvatar } from "./UserAvatar";

const { Text } = Typography;

type MenuPopoverContentProps = {
  name: string;
  role: string;
  avatar?: string;
  onAccountInfoClicked?: () => void;
  onChangePasswordClicked?: () => void;
  onLogoutClicked?: () => void;
};

export const MenuPopoverContent: React.FC<MenuPopoverContentProps> = ({
  avatar,
  role,
  name,
  onAccountInfoClicked,
  onChangePasswordClicked,
  onLogoutClicked,
}) => {
  const t = useTranslate();

  return (
    <Card
      bordered={false}
      style={{ width: 300, boxShadow: "none" }}
      bodyStyle={{ padding: 8 }}
    >
      <Row gutter={14} style={{ paddingTop: 8, paddingBottom: 16 }}>
        <Col flex="54px">
          <UserAvatar size={50} src={avatar} name={name} />
        </Col>
        <Col flex="auto">
          <Space direction="vertical">
            <Row>
              <Text ellipsis strong>
                {name}
              </Text>
            </Row>
            <Row>
              <Text>{role}</Text>
            </Row>
          </Space>
        </Col>
      </Row>

      {onAccountInfoClicked && (
        <Row
          style={{ paddingTop: 6, paddingBottom: 6, cursor: "pointer" }}
          onClick={onAccountInfoClicked}
        >
          <Space>
            <UserOutlined />
            <Text>{t("menu.popover.account_info")}</Text>
          </Space>
        </Row>
      )}

      {onChangePasswordClicked && (
        <Row
          style={{ paddingTop: 6, paddingBottom: 6, cursor: "pointer" }}
          onClick={onChangePasswordClicked}
        >
          <Space>
            <EditOutlined />
            <Text>{t("menu.popover.change_password")}</Text>
          </Space>
        </Row>
      )}

      {onLogoutClicked && (
        <Row
          style={{ paddingTop: 6, paddingBottom: 6, cursor: "pointer" }}
          onClick={onLogoutClicked}
        >
          <Space>
            <LogoutOutlined style={{ color: "#E60019" }} />
            <Text type="danger">{t("menu.popover.logout")}</Text>
          </Space>
        </Row>
      )}
    </Card>
  );
};
