import { DownOutlined } from "@ant-design/icons";
import { useGetLocale, useSetLocale } from "@refinedev/core";
import { Avatar, Button, Dropdown, MenuProps, Space } from "antd";
import { useTranslation } from "react-i18next";

const getLanguageLabel = (locale?: string) => {
  switch (locale) {
    case "en":
      return "English";
    case "de":
      return "German";
    case "vi":
      return "Việt Nam";
    default:
      return "Unknown";
  }
};

export const LanguageDropdown: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const currentLocale = locale();

  const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label: getLanguageLabel(lang),
    }));

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectedKeys: currentLocale ? [currentLocale] : [],
      }}
    >
      <Button type="text">
        <Space>
          <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
          {getLanguageLabel(currentLocale)}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
