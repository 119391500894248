import {
  useApiUrl,
  useCustom,
  useGetIdentity,
  useLogout,
  useTranslate,
} from "@refinedev/core";
import { App, Popover, Typography } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PATHS, ROLE_LIST } from "config";
import { IUser, IUserIdentity } from "interfaces";

import { MenuPopoverContent } from "./MenuPopoverContent";
import { UserAvatar } from "./UserAvatar";

export const MenuPopover: React.FC = () => {
  const { modal } = App.useApp();
  const t = useTranslate();

  const { data: user } = useGetIdentity<IUserIdentity>();
  const navigate = useNavigate();

  const apiUrl = useApiUrl();
  const { data } = useCustom<IUser>({
    url: `${apiUrl}/users/me`,
    method: "get",
  });

  const displayName =
    data?.data?.fullName ||
    data?.data?.username ||
    user?.name ||
    user?.username ||
    t("menu.popover.unknown");

  const roleName = useMemo(() => {
    if (!user?.roles?.length) return t("menu.popover.unknown");

    return user.roles
      .map((role) => ROLE_LIST(t).find((item) => item.value === role)?.label)
      .join(",");
  }, [user, t]);

  const [popupOpen, setPopupOpen] = useState(false);
  const { mutate: logout } = useLogout<{ redirectPath: string }>();

  return (
    <Popover
      title=""
      trigger="click"
      showArrow={false}
      placement="bottomRight"
      onOpenChange={(visible) => setPopupOpen(visible)}
      open={popupOpen}
      content={
        <MenuPopoverContent
          name={displayName}
          role={roleName}
          avatar={user?.avatar}
          onChangePasswordClicked={() => {
            if (window.location.pathname !== PATHS.updatePassword) {
              navigate(PATHS.updatePassword);
            }
            setPopupOpen(false);
          }}
          onAccountInfoClicked={() => {
            if (window.location.pathname !== PATHS.accountMe) {
              navigate(PATHS.accountMe);
            }
            setPopupOpen(false);
          }}
          onLogoutClicked={() => {
            modal.confirm({
              icon: null,
              title: (
                <Typography.Title level={5}>
                  {t("dialogs.logout.title")}
                </Typography.Title>
              ),
              content: (
                <Typography.Text>{t("dialogs.logout.content")}</Typography.Text>
              ),
              okText: t("buttons.agree"),
              cancelText: t("buttons.cancel"),
              onOk() {
                logout({
                  redirectPath: PATHS.home,
                });
              },
            });
            setPopupOpen(false);
          }}
        />
      }
    >
      <div
        style={{
          cursor: "pointer",
          backgroundColor: "#f4f4f4",
          padding: "6px 12px",
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          gap: 8,
        }}
      >
        <UserAvatar size={32} src={user?.avatar} name={displayName} />
        <span style={{ lineHeight: 1.65, fontWeight: 600 }}>{displayName}</span>
        {/* <CaretDownFilled style={{ color: "#7F879E", marginLeft: 3 }} /> */}
      </div>
    </Popover>
  );
};
