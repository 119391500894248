import { Create, useForm } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row, TimePicker } from "antd";
import React from "react";

import {
  API_RESOURCES,
  DEFAULT_INPUT_MAX_LENGTH,
  DEFAULT_MINUTE_STEP,
  DEFAULT_TEXT_AREA_MAX_LENGTH,
} from "config";
import { IClassPeriod } from "interfaces";
import { convertDayjsToTimePeriodString } from "utils/timeHelpers";

export const ClassPeriodCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    formProps: { onFinish: _, ...restFormProps },
    saveButtonProps,
  } = useForm<IClassPeriod, HttpError>({
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("class_period.notifications.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("class_period.notifications.createSuccess"),
    },
  });

  const { list } = useNavigation();
  const apiUrl = useApiUrl();
  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: createClassPeriod } = useCustomMutation<IClassPeriod>();

  const handleCreateClassPeriod = async (values: IClassPeriod) => {
    createClassPeriod(
      {
        url: `${apiUrl}/class_period/create`,
        method: "post",
        values: {
          ...values,
          startTime: convertDayjsToTimePeriodString(values?.time?.[0]),
          endTime: convertDayjsToTimePeriodString(values?.time?.[1]),
          description: values?.description ?? "",
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("class_period.notifications.createError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("class_period.notifications.createSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.classPeriods);
          }, 100);
        },
      }
    );
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...restFormProps}
        layout="vertical"
        onFinish={(values) => handleCreateClassPeriod(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("class_period.fields.code")}
              name={["code"]}
              rules={[
                {
                  required: true,
                  message: t("class_period.errors.codeRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("class_period.placeholders.code")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("class_period.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("class_period.errors.nameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("class_period.placeholders.name")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("class_period.fields.time")}
              name={["time"]}
              rules={[
                {
                  required: true,
                  message: t("class_period.errors.timeRequired"),
                },
              ]}
            >
              <TimePicker.RangePicker
                showSecond={false}
                format={"HH:mm"}
                style={{ width: "100%" }}
                minuteStep={DEFAULT_MINUTE_STEP}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("class_period.fields.description")}
              name={["description"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("class_period.errors.timeRequired"),
              //   },
              // ]}
            >
              <Input.TextArea
                rows={4}
                placeholder={t("class_period.placeholders.description")}
                maxLength={DEFAULT_TEXT_AREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
