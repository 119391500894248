import { useTranslate } from "@refinedev/core";
import { Space, Table, Typography } from "antd";

import { IUser } from "interfaces";

interface StudentTableProps {
  title: string;
  titleColor: string;
  studentList?: IUser[];
  withReason?: boolean;
}

const StudentTable: React.FC<StudentTableProps> = ({
  studentList,
  title,
  titleColor,
  withReason,
}) => {
  const t = useTranslate();

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Title level={5} style={{ color: titleColor }}>
        {title}
      </Typography.Title>

      <Table rowKey="id" dataSource={studentList} scroll={{ x: 300 }}>
        <Table.Column<IUser>
          dataIndex="key"
          key="key"
          title={t("users.fields.idx")}
          render={(_, record, index) => {
            return index + 1;
          }}
        />
        <Table.Column
          dataIndex={"fullName"}
          key="fullName"
          title={t("users.fields.fullName")}
        />
        <Table.Column
          dataIndex={"username"}
          key="username"
          title={t("users.fields.username")}
        />
        <Table.Column
          dataIndex={"email"}
          key="email"
          title={t("users.fields.email")}
        />
        <Table.Column
          dataIndex={"mobile"}
          key="mobile"
          title={t("users.fields.mobile")}
        />
        {withReason && (
          <Table.Column
            dataIndex={"reason"}
            key="reason"
            title={t("users.fields.reason")}
          />
        )}
      </Table>
    </Space>
  );
};

export default StudentTable;
