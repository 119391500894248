import { Space, Typography } from "antd";
import { RequiredMark } from "components/form";
import { CSSProperties } from "react";

export interface WithLabelProps {
  label?: string;
  labelStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
  children?: React.ReactNode;
  hasRequiredMark?: boolean;
}

const WithLabel: React.FC<WithLabelProps> = ({
  label,
  labelStyles,
  wrapperStyles,
  children,
  hasRequiredMark,
}) => {
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", ...(wrapperStyles || {}) }}
    >
      {label && (
        <div>
          {hasRequiredMark && <RequiredMark />}
          <Typography.Text
            style={{
              color: "#7f879e",
              ...(labelStyles || {}),
            }}
          >
            {label}
          </Typography.Text>
        </div>
      )}
      {children}
    </Space>
  );
};

export default WithLabel;
