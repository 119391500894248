export const BrandWithWhiteBg = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 4, marginTop: -10 }}>
      <span style={{ color: "#03C3FE", fontWeight: 600, fontSize: 24 }}>
        JMT
      </span>
      <span
        style={{
          backgroundColor: "#03C3FE",
          width: "5px",
          height: "5px",
          borderRadius: "50%",
        }}
      />
      <span style={{ color: "#1C2634", fontWeight: 600, fontSize: 24 }}>
        EduHub
      </span>
    </div>
  );
};

export const BrandWithDarkBg = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <span style={{ color: "#03C3FE", fontWeight: 600, fontSize: 24 }}>
          JMT
        </span>
        <span
          style={{
            backgroundColor: "#03C3FE",
            width: "5px",
            height: "5px",
            borderRadius: "50%",
          }}
        />
        <span style={{ color: "#ffffff", fontWeight: 600, fontSize: 24 }}>
          EduHub
        </span>
      </div>
    );
  };