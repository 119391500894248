import { PlusOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Space, Table } from "antd";
import React from "react";
import dayjs from "dayjs";

import { PageList } from "components/pages";
import { IHoliday } from "interfaces";
import { DATE_FORMAT } from "config";

export const HolidayList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();

  const { tableProps, searchFormProps, filters } = useTable<
    IHoliday,
    HttpError
  >({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;
  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <PageList
      searchValue={getDefaultFilter("q", filters, "eq")}
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[
        <CreateButton key="create" type="primary" icon={<PlusOutlined />}>
          {t("buttons.create")}
        </CreateButton>,
      ]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("holidays.fields.idx")} />
        <Table.Column dataIndex="name" title={t("holidays.fields.name")} />
        <Table.Column
          dataIndex="date"
          title={t("holidays.fields.date")}
          render={(date) => (date ? dayjs(date).format(DATE_FORMAT) : "")}
        />
        <Table.Column
          title={t("holidays.fields.actions")}
          dataIndex="actions"
          render={(_, record: IHoliday) => {
            return (
              <Space>
                {resource?.canEdit && (
                  <EditButton hideText size="small" recordItemId={record.id} />
                )}
                {resource?.canShow && (
                  <ShowButton hideText size="small" recordItemId={record.id} />
                )}
                {resource?.meta?.canDelete && (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    errorNotification={(error) => {
                      return {
                        type: "error",
                        message:
                          (error as any)?.message ??
                          t("holidays.notifications.deleteError"),
                      };
                    }}
                    successNotification={{
                      type: "success",
                      message: t("holidays.notifications.deleteSuccess"),
                    }}
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </PageList>
  );
};
