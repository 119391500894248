import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useApiUrl,
  useCustomMutation,
  useGetIdentity,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { App, Button, Space, Table, Tag } from "antd";
import React, { useState } from "react";

import axiosInstance from "axiosInstance";
import { PageList } from "components/pages";
import { ROLE_LIST } from "config";
import useUserRole from "hooks/useUserRole";
import { IUser, IUserIdentity, IUserRole } from "interfaces";
import { getFileExtension } from "utils/commonHelper";
import {
  downloadData,
  handleUploadFile,
  openPublicPath,
} from "utils/domHelper";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { notification } = App.useApp();
  const { resource } = useResource();

  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch: refetchUsers },
    filters,
  } = useTable<IUser, HttpError>({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;
  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  const { hasAdminRole, hasManagerRole } = useUserRole();
  const { data: user } = useGetIdentity<IUserIdentity>();
  const apiUrl = useApiUrl();

  // const { isRefetching: isRefetchingExport, refetch: refetchExport } =
  //   useCustom({
  //     url: `${apiUrl}/users/export`,
  //     method: "get",
  //     queryOptions: {
  //       enabled: false,
  //       retry: 0,
  //       onSuccess(data) {
  //         FileSaver.saveAs(
  //           new Blob([data?.data as any]),
  //           `${new Date().getTime()}_danh_sach_nguoi_dung.xlsx`
  //         );
  //       },
  //     },
  //     errorNotification(error: any) {
  //       return {
  //         type: "error",
  //         message: error?.message ?? t("users.notifications.exportError"),
  //       };
  //     },
  //     successNotification: {
  //       type: "success",
  //       message: t("users.notifications.exportSuccess"),
  //     },
  //   });

  const [isRefetchingExport, setIsRefetchingExport] = useState(false);

  const refetchExport = async () => {
    try {
      setIsRefetchingExport(true);
      const { data } = await axiosInstance.get(`${apiUrl}/users/export`, {
        responseType: "blob",
      });
      downloadData(data, t("users.exports.fileName"));
      notification.success({
        message: t("users.notifications.exportSuccess"),
      });
    } catch (error: any) {
      notification.error({
        message: error?.message ?? t("users.notifications.exportError"),
      });
    } finally {
      setIsRefetchingExport(false);
    }
  };

  const { mutate: importUsers, isLoading: isImportingUsers } =
    useCustomMutation<{
      file: Blob;
    }>();

  const handleImportUsers = async () => {
    const input = handleUploadFile({
      accept: ".xlsx",
      onChange: (event: Event) => {
        if (!input || !input.files) return;

        if (!input || !input.files || input.files.length < 1) {
          document.body.removeChild(input);
          return;
        }

        const file = input.files[0];
        const ext = getFileExtension(file?.name || "");

        if (ext !== "xlsx") {
          notification.error({
            message: t("users.notifications.importErrorChooseFileTitle"),
            description: "users.notifications.importErrorChooseFileDesc",
          });
          document.body.removeChild(input);
          return;
        }

        const formData = new FormData();
        formData.append("file", file);

        importUsers(
          {
            url: `${apiUrl}/users/students/import`,
            method: "post",
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
            values: {
              file,
            },
            errorNotification(error) {
              return {
                type: "error",
                message: error?.message ?? t("users.notifications.importError"),
              };
            },
            successNotification: {
              type: "success",
              message: t("users.notifications.importSuccess"),
            },
          },
          {
            onSuccess() {
              document.body.removeChild(input);
              refetchUsers();
            },
            onError() {
              document.body.removeChild(input);
            },
          }
        );
      },
    });
  };

  return (
    <PageList
      searchValue={getDefaultFilter("q", filters, "eq")}
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[
        <Button
          type="dashed"
          key="export_template"
          icon={<DownloadOutlined />}
          onClick={() => {
            openPublicPath(
              `/templates/user_import_template.xlsx`,
              "blank",
              "noopener"
            );
          }}
        >
          {t("buttons.export_template")}
        </Button>,
        <Button
          key="upload"
          icon={<CloudUploadOutlined />}
          loading={isImportingUsers}
          disabled={isImportingUsers}
          onClick={() => handleImportUsers()}
        >
          {t("buttons.import")}
        </Button>,
        <Button
          key="download"
          icon={<CloudDownloadOutlined />}
          loading={isRefetchingExport}
          disabled={isRefetchingExport}
          onClick={() => refetchExport()}
        >
          {t("buttons.export")}
        </Button>,
        <CreateButton key="create" type="primary" icon={<PlusOutlined />}>
          {t("buttons.create")}
        </CreateButton>,
      ]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("users.fields.idx")} />
        <Table.Column dataIndex="fullName" title={t("users.fields.fullName")} />
        <Table.Column dataIndex="username" title={t("users.fields.username")} />
        <Table.Column dataIndex="email" title={t("users.fields.email")} />
        <Table.Column dataIndex="position" title={t("users.fields.position")} />
        <Table.Column
          dataIndex="roles"
          title={t("users.fields.role")}
          render={(roles) => (
            <Space>
              {(roles as IUserRole[])?.map((role) => {
                const _role = ROLE_LIST(t).find((it) => it.value === role.code);
                if (!_role) return null;
                return (
                  <Tag key={_role.value} color={_role.color}>
                    {_role.label}
                  </Tag>
                );
              })}
            </Space>
          )}
        />
        <Table.Column
          title={t("users.fields.actions")}
          dataIndex="actions"
          render={(_, record: IUser) => {
            const isMe = user?.username === record?.username;
            const isAdmin = Boolean(
              record?.roles?.find((it) => it.code === "ADMIN")
            );
            const canEditDelete = hasAdminRole || hasManagerRole;
            return (
              <Space>
                {resource?.canShow && (
                  <ShowButton hideText size="small" recordItemId={record.id} />
                )}
                {resource?.canEdit && canEditDelete && (
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    disabled={isMe || isAdmin}
                  />
                )}
                {resource?.meta?.canDelete && canEditDelete && (
                  <DeleteButton
                    disabled={isMe || isAdmin}
                    hideText
                    size="small"
                    recordItemId={record.id}
                    errorNotification={(error) => {
                      return {
                        type: "error",
                        message:
                          (error as any)?.message ??
                          t("users.notifications.deleteError"),
                      };
                    }}
                    successNotification={{
                      type: "success",
                      message: t("users.notifications.deleteSuccess"),
                    }}
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </PageList>
  );
};
