import { Layout as AntdLayout } from "antd";
import React from "react";

import { DEFAULT_SIDER_DARK_BG_COLOR, UNAUTHEN_SIDER_WIDTH } from "config";
import { LanguageDropdown } from "../header/LanguageDropdown";

interface UnauthenticatedLayoutProps {
  children: React.ReactNode;
}

export const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children,
}) => {
  return (
    <AntdLayout style={{ height: "100vh" }}>
      <AntdLayout.Sider
        breakpoint="xl"
        width={UNAUTHEN_SIDER_WIDTH}
        collapsedWidth={0}
        trigger={null}
        style={{
          backgroundColor: DEFAULT_SIDER_DARK_BG_COLOR,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: "url(images/login/img_slogan.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </AntdLayout.Sider>

      <AntdLayout.Content
        style={{
          margin: "auto",
          height: "100vh",
          backgroundColor: "#141C22",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: 16, right: 16 }}>
          <LanguageDropdown />
        </div>

        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          {children}
        </div>
      </AntdLayout.Content>
    </AntdLayout>
  );
};
