import { useGetIdentity } from "@refinedev/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoadingScreen from "components/loadingScreen";
import { PATHS, ROLE_BASED_RESOURCES } from "config";
import { IUserIdentity, IUserRoleCode } from "interfaces";

const Splash: React.FC = () => {
  const { data: identity } = useGetIdentity<IUserIdentity>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!identity) {
      navigate(PATHS.notFound);
      return;
    }

    if (!identity?.roles || identity.roles.length === 0) {
      navigate(PATHS.notFound);
      return;
    }

    const role = identity.roles[0] as IUserRoleCode;
    const availableResources = ROLE_BASED_RESOURCES[role];
    if (!availableResources || availableResources.length === 0) {
      navigate(PATHS.notFound);
      return;
    }

    const initialRoute = `/${availableResources[0]}`;
    navigate(initialRoute);
  }, [identity, navigate]);

  return (
    <div style={{ position: "relative", minHeight: 360 }}>
      <LoadingScreen
        wrapperStyle={{
          position: "absolute",
        }}
      />
    </div>
  );
};

export default Splash;
