import { useTranslate } from "@refinedev/core";
import { Col, Row } from "antd";
import dayjs from "dayjs";

import TextBox from "components/textBox";
import { DATE_FORMAT } from "config";
import { ITimeTableReportExtraClass } from "interfaces";
import { formatClassPeriod } from "utils/modelHelper";

interface CommonInfoProps {
  record: ITimeTableReportExtraClass;
}

const CommonInfo: React.FC<CommonInfoProps> = ({ record }) => {
  const t = useTranslate();

  return (
    <Row gutter={[16, 24]} style={{ marginTop: 16, marginBottom: 16 }}>
      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={record?.date ? dayjs(record?.date).format(DATE_FORMAT) : ""}
          label={t("reports_normal_classes.details.fields.date")}
        />
      </Col>
      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={formatClassPeriod(record?.classPeriod)}
          label={t("reports_normal_classes.details.fields.classPeriod")}
        />
      </Col>
      <Col xs={24} md={12} lg={8}>
        <TextBox
          value={
            record?.timetableStatus
              ? t(`common.timetableStatus_${record?.timetableStatus}`)
              : ""
          }
          label={t(`common.status`)}
        />
      </Col>
    </Row>
  );
};

export default CommonInfo;
