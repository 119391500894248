import { useTranslate } from "@refinedev/core";
import { Tag } from "antd";

import { ITimeTableStatus } from "interfaces";

interface HighlightTimetableStatusProps {
  status?: ITimeTableStatus;
}

const HighlightTimetableStatus: React.FC<HighlightTimetableStatusProps> = ({
  status,
}) => {
  const t = useTranslate();

  const color =
    status === 1
      ? "default"
      : status === 3
      ? "success"
      : status === 2
      ? "processing"
      : status === 4
      ? "warning"
      : "default";

  return status ? (
    <Tag color={color}>{t(`common.timetableStatus_${status}`)}</Tag>
  ) : (
    <></>
  );
};

export default HighlightTimetableStatus;
