import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { PageHeader, useModal } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Modal, Popconfirm, Space, Table, Typography } from "antd";
import { useState } from "react";

import { IUser } from "interfaces";
import AddStudentContent from "./addStudentContent";

interface StudentTableProps {
  studentList: IUser[];
  onStudentListChanged?: (list: IUser[]) => void;
  hasAdd?: boolean;
  hasDelete?: boolean;
}

export const StudentTable: React.FC<StudentTableProps> = ({
  studentList = [],
  onStudentListChanged,
  hasAdd,
  hasDelete,
}) => {
  const t = useTranslate();

  const [upsertStudentValues, setUpsertStudentValues] = useState<
    IUser[] | undefined
  >(undefined);

  const {
    modalProps: modalAddStudentProps,
    show: showAddStudent,
    close: closeAddStudent,
  } = useModal();

  const addStudents = async (users: IUser[]) => {
    const newStudentlist = [...studentList];
    for (let i = 0; i < users.length; i++) {
      newStudentlist.push({
        ...users[i],
        key: studentList.length + i + 1,
      });
    }

    closeAddStudent();
    setUpsertStudentValues(undefined);
    onStudentListChanged?.(newStudentlist);
  };

  const deleteStudent = async (record: IUser) => {
    const newStudentlist = studentList
      .filter((student) => student.key !== record.key)
      .map((schedule, index) => ({
        ...schedule,
        key: index + 1,
      }));

    onStudentListChanged?.(newStudentlist);
  };

  const onAdd = () => {
    showAddStudent();
  };

  const onDelete = (record: IUser) => {
    deleteStudent(record);
  };

  const modalEnableOk = () => {
    return Boolean(upsertStudentValues);
  };

  return (
    <>
      <PageHeader
        backIcon={null}
        style={{ padding: 0, paddingBottom: 10 }}
        title={
          <Typography.Text
            style={{
              fontSize: 16,
            }}
          >
            {t("normal_class.participants.title")}
          </Typography.Text>
        }
        extra={
          hasAdd
            ? [
                <Button
                  key="add-student"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={onAdd}
                >
                  {t("normal_class.participants.buttons.add")}
                </Button>,
              ]
            : []
        }
      />

      <Table rowKey="key" dataSource={studentList} scroll={{ x: 300 }}>
        <Table.Column<IUser>
          dataIndex="key"
          key="key"
          title={t("normal_class.participants.fields.idx")}
          render={(_, record, index) => {
            return index + 1;
          }}
        />

        <Table.Column
          dataIndex={"fullName"}
          key="fullName"
          title={t("normal_class.participants.fields.fullName")}
        />

        <Table.Column
          dataIndex={"username"}
          key="username"
          title={t("normal_class.participants.fields.username")}
        />

        <Table.Column
          dataIndex={"email"}
          key="email"
          title={t("normal_class.participants.fields.email")}
        />

        <Table.Column
          dataIndex={"mobile"}
          key="mobile"
          title={t("normal_class.participants.fields.mobile")}
        />

        {hasDelete && (
          <Table.Column<IUser>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {hasDelete && (
                  <Popconfirm
                    title={t("buttons.confirm")}
                    onConfirm={() => onDelete(record)}
                    onCancel={() => null}
                    okText={t("buttons.clear")}
                    cancelText={t("buttons.cancel")}
                    okType="danger"
                  >
                    <Button icon={<DeleteOutlined />} danger size="small" />
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        )}
      </Table>

      <Modal
        {...modalAddStudentProps}
        width={600}
        title={t("normal_class.participants.modalTitle")}
        okButtonProps={{
          disabled: !modalEnableOk(),
        }}
        onCancel={() => {
          setUpsertStudentValues(undefined);
          closeAddStudent();
        }}
        onOk={() => {
          if (modalEnableOk()) {
            addStudents(upsertStudentValues!);
          }
        }}
      >
        <AddStudentContent
          studentList={studentList}
          upsertStudentValues={upsertStudentValues}
          setUpsertStudentValues={setUpsertStudentValues}
        />
      </Modal>
    </>
  );
};
