import { ShowButton, useTable } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Space, Table } from "antd";
import React from "react";

import { PageList } from "components/pages";
import { IUser } from "interfaces";

export const ReportStudentList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();

  const { tableProps, searchFormProps, filters } = useTable<IUser, HttpError>({
    resource: "reports/student/lists",
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;
  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <PageList
      searchValue={getDefaultFilter("q", filters, "eq")}
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("users.fields.idx")} />
        <Table.Column dataIndex="fullName" title={t("users.fields.fullName")} />
        <Table.Column dataIndex="username" title={t("users.fields.username")} />
        <Table.Column dataIndex="email" title={t("users.fields.email")} />
        <Table.Column dataIndex="classCode" title={t("users.fields.class")} />
        <Table.Column dataIndex="position" title={t("users.fields.position")} />
        <Table.Column
          title={t("users.fields.actions")}
          dataIndex="actions"
          render={(_, record: IUser) => {
            return (
              <Space>
                {resource?.canShow && (
                  <ShowButton hideText size="small" recordItemId={record.id} />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </PageList>
  );
};
