export const RequiredMark: React.FC = () => {
  return (
    <span
      style={{
        color: "#ff4d4f",
        fontSize: "14px",
        fontFamily: "SimSun,sans-serif",
        lineHeight: 1,
        display: "inline-block",
        marginInlineEnd: 4,
      }}
    >
      *
    </span>
  );
};
