import { PlusOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DeleteButton,
  EditButton,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Space, Table } from "antd";
import React from "react";

import { PageList } from "components/pages";
import { IClassPeriod, INormalClass, IUser } from "interfaces";
import {
  formatClassPeriod,
  formatSchoolYear,
  formatUser,
} from "utils/modelHelper";

export const NormalClassList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();

  const { tableProps, searchFormProps, filters } = useTable<
    INormalClass,
    HttpError
  >({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;
  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  // const apiUrl = useApiUrl();

  // const { isRefetching: isRefetchingExport, refetch: refetchExport } =
  //   useCustom({
  //     url: `${apiUrl}/export/normal_class`,
  //     method: "get",
  //     queryOptions: {
  //       enabled: false,
  //       retry: 0,
  //     },
  //     errorNotification(error: any) {
  //       return {
  //         type: "error",
  //         message:
  //           error?.message ?? t("normal_class.notifications.exportError"),
  //       };
  //     },
  //     successNotification: {
  //       type: "success",
  //       message: t("normal_class.notifications.exportSuccess"),
  //     },
  //   });

  // const { mutate: importUsers, isLoading: isImportingUsers } =
  //   useCustomMutation<{
  //     key: string;
  //   }>();

  // const handleImportUsers = async (key?: string) => {
  //   importUsers({
  //     url: `${apiUrl}/import/normal_class`,
  //     method: "post",
  //     values: {
  //       key,
  //     },
  //     errorNotification(error) {
  //       return {
  //         type: "error",
  //         message:
  //           error?.message ?? t("normal_class.notifications.importError"),
  //       };
  //     },
  //     successNotification: {
  //       type: "success",
  //       message: t("normal_class.notifications.importSuccess"),
  //     },
  //   });
  // };

  return (
    <PageList
      searchValue={getDefaultFilter("q", filters, "eq")}
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[
        // <Button
        //   key="download"
        //   icon={<CloudDownloadOutlined />}
        //   loading={isImportingUsers}
        //   disabled={true || isImportingUsers}
        //   onClick={() => handleImportUsers()}
        // >
        //   {t("buttons.import")}
        // </Button>,
        // <Button
        //   key="upload"
        //   icon={<CloudUploadOutlined />}
        //   loading={isRefetchingExport}
        //   disabled={true || isRefetchingExport}
        //   onClick={() => refetchExport()}
        // >
        //   {t("buttons.export")}
        // </Button>,
        <CreateButton key="create" type="primary" icon={<PlusOutlined />}>
          {t("buttons.create")}
        </CreateButton>,
      ]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("normal_class.fields.idx")} />
        <Table.Column
          dataIndex="schoolYear"
          title={t("normal_class.fields.schoolYear")}
          render={(schoolYear) => formatSchoolYear(schoolYear)}
        />
        <Table.Column dataIndex="name" title={t("normal_class.fields.name")} />
        <Table.Column dataIndex="code" title={t("normal_class.fields.code")} />
        <Table.Column
          dataIndex="classPeriod"
          title={t("normal_class.fields.classPeriod")}
          render={(classPeriod: IClassPeriod) => formatClassPeriod(classPeriod)}
        />
        <Table.Column
          dataIndex="teachers"
          title={t("normal_class.fields.teacher")}
          render={(teachers: IUser[]) => {
            return teachers?.map((teacher) => formatUser(teacher)).join(", ");
          }}
        />
        {/* <Table.Column
          dataIndex="description"
          title={t("normal_class.fields.description")}
        /> */}
        <Table.Column
          title={t("normal_class.fields.actions")}
          dataIndex="actions"
          render={(_, record: INormalClass) => {
            return (
              <Space>
                {resource?.canEdit && (
                  <EditButton hideText size="small" recordItemId={record.id} />
                )}
                {resource?.canShow && (
                  <ShowButton hideText size="small" recordItemId={record.id} />
                )}
                {resource?.meta?.canDelete && (
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    errorNotification={(error) => {
                      return {
                        type: "error",
                        message:
                          (error as any)?.message ??
                          t("normal_class.notifications.deleteError"),
                      };
                    }}
                    successNotification={{
                      type: "success",
                      message: t("normal_class.notifications.deleteSuccess"),
                    }}
                  />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </PageList>
  );
};
