import { ShowButton, useTable } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Space, Table } from "antd";
import React from "react";

import { PageList } from "components/pages";
import { API_RESOURCES } from "config";
import { IExtraClass } from "interfaces";
import { formatOperationTime } from "pages/extra-classes/private/util";
import { formatSchoolYear } from "utils/modelHelper";

export const ReportExtraClassList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();

  const { tableProps, searchFormProps, filters } = useTable<
    IExtraClass,
    HttpError
  >({
    resource: API_RESOURCES.extraClasses,
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;
  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <PageList
      searchValue={getDefaultFilter("q", filters, "eq")}
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("extra_class.fields.idx")} />
        <Table.Column
          dataIndex="schoolYear"
          title={t("extra_class.fields.schoolYear")}
          render={(schoolYear) => formatSchoolYear(schoolYear)}
        />
        <Table.Column dataIndex="name" title={t("extra_class.fields.name")} />
        <Table.Column dataIndex="code" title={t("extra_class.fields.code")} />
        <Table.Column<IExtraClass>
          dataIndex="operatingTime"
          title={t("extra_class.fields.operatingTime")}
          render={(_, record) =>
            formatOperationTime(record?.startDate, record?.endDate)
          }
        />
        <Table.Column
          title={t("extra_class.fields.actions")}
          dataIndex="actions"
          render={(_, record: IExtraClass) => {
            return (
              <Space>
                {resource?.canShow && (
                  <ShowButton hideText size="small" recordItemId={record.id} />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </PageList>
  );
};
