import { useLink, useLogin, useTranslate } from "@refinedev/core";
import { Button, Form, Input, Typography } from "antd";

import imgLogo from "assets/img_logo.svg";
import { BrandWithWhiteBg } from "components/brand";
import { UnauthenticatedLayout } from "components/layout";
import { ILoginTypes } from "interfaces";

interface LoginProps {
  forgotPasswordLink?: string;
  registerLink?: string;
}

const Login: React.FC<LoginProps> = ({
  forgotPasswordLink, // = "/forgot-password",
  registerLink, // = "/register",
}) => {
  const t = useTranslate();
  const [form] = Form.useForm<ILoginTypes>();
  const Link = useLink();
  const { mutate: login, isLoading } = useLogin<ILoginTypes>();

  const formTitle = (
    <div
      style={{ display: "flex", gap: 20, alignItems: "center", width: "100%" }}
    >
      <div>
        <img
          src={imgLogo}
          alt="JMT-EduHub-logo"
          style={{
            width: "80px",
            borderRadius: 10,
            backfaceVisibility: "hidden",
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <BrandWithWhiteBg />
        <div>{t("pages.login.title")}</div>
      </div>
    </div>
  );

  const formContent = (
    <Form<ILoginTypes>
      layout="vertical"
      form={form}
      onFinish={(values) => login({ ...values })}
      requiredMark={false}
      style={{ width: "100%" }}
    >
      <Form.Item
        name="username"
        label={t("pages.login.fields.username")}
        rules={[
          {
            required: true,
            message: t("pages.login.errors.usernameRequired"),
          },
        ]}
      >
        <Input
          size="large"
          placeholder={t("pages.login.placeholders.username")}
          autoComplete="username"
          // prefix={<UserOutlined />}
        />
      </Form.Item>

      <Form.Item
        name="password"
        label={t("pages.login.fields.password", "Password")}
        rules={[
          {
            required: true,
            message: t("pages.login.errors.passRequired"),
          },
        ]}
        style={{ marginBottom: "12px" }}
      >
        <Input.Password
          type="password"
          placeholder={t("pages.login.placeholders.password")}
          size="large"
          autoComplete="current-password"
          // prefix={<LockOutlined />}
        />
      </Form.Item>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {forgotPasswordLink && (
          <Link
            to={forgotPasswordLink}
            style={{
              fontSize: 12,
              marginLeft: "auto",
            }}
          >
            {t("pages.login.buttons.forgotPassword")}
          </Link>
        )}
      </div>

      <Form.Item style={{ marginTop: "30px" }}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          block
        >
          {t("pages.login.signin")}
        </Button>
      </Form.Item>

      <div style={{ marginTop: 8, textAlign: "center" }}>
        {registerLink && (
          <Typography.Text style={{ fontSize: 12 }}>
            {t("pages.login.buttons.noAccount")}{" "}
            <Link to={registerLink}>{t("common.signup")}</Link>
          </Typography.Text>
        )}
      </div>
    </Form>
  );

  return (
    <UnauthenticatedLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          gap: 25,
          width: 400,
          maxWidth: "100%",
        }}
      >
        {formTitle}
        {formContent}
      </div>
    </UnauthenticatedLayout>
  );
};

export default Login;
