import {
  BarsOutlined,
  DashboardOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { RefineThemedLayoutSiderProps } from "@refinedev/antd";
import {
  CanAccess,
  ITreeMenu,
  pickNotDeprecated,
  useIsExistAuthentication,
  useLink,
  useLogout,
  useMenu,
  useRefineContext,
  useResource,
  useTitle,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import {
  App,
  Button,
  Drawer,
  Grid,
  Layout,
  Menu,
  Typography,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";

import { BrandWithDarkBg } from "components/brand";
import {
  DEFAULT_SIDER_COLLAPSED_WIDTH,
  DEFAULT_SIDER_WIDTH,
  PATHS,
  SIDER_HEADER_COLLAPSED_HEIGHT,
  SIDER_HEADER_HEIGHT,
} from "config";
import { Title as DefaultLayoutTitle } from "../title";
import { drawerButtonStyles } from "./styles";

const { SubMenu } = Menu;
const { useToken } = theme;

type SiderProps = RefineThemedLayoutSiderProps;

function findDefaultOpenKeys(resource: any, resources: any) {
  if (!resource) return [];

  let parent = resources.find((res: any) => res.name === resource.meta?.parent);
  const keys = [resource.route ?? ""];

  while (parent) {
    keys.push(parent.route ?? "");
    const parentName = parent?.meta?.parent;
    parent = resources.find((res: any) => res.name === parentName);
  }

  return keys;
}

export const Sider: React.FC<SiderProps> = ({
  Title: TitleFromProps,
  render,
  meta,
}) => {
  const { modal } = App.useApp();
  const { token } = useToken();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const isExistAuthentication = useIsExistAuthentication();
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const Link = useLink();
  const TitleFromContext = useTitle();
  const t = useTranslate();
  const { menuItems, selectedKey } = useMenu({ meta });
  const breakpoint = Grid.useBreakpoint();
  const { hasDashboard } = useRefineContext();
  // const hasDashboard = useHasDashboard();

  const { resource, resources } = useResource();
  const [openKeys, setOpenKeys] = useState<any>(null);
  useEffect(() => {
    if (!openKeys || !openKeys.length) {
      setOpenKeys(findDefaultOpenKeys(resource, resources));
    }
  }, [resource, resources, openKeys]);

  const { mutate: mutateLogout } = useLogout();

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const RenderToTitle =
    TitleFromProps ?? TitleFromContext ?? DefaultLayoutTitle;

  const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
    return tree.map((item: ITreeMenu) => {
      const {
        icon,
        label,
        route,
        key,
        name,
        children,
        parentName,
        meta,
        options,
      } = item;

      if (children.length > 0) {
        return (
          <CanAccess
            key={item.key}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            <SubMenu
              key={item.key}
              icon={icon ?? <UnorderedListOutlined />}
              title={label}
            >
              {renderTreeView(children, selectedKey)}
            </SubMenu>
          </CanAccess>
        );
      }
      const isSelected = key === selectedKey;
      const isRoute = !(
        pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
          undefined && children.length === 0
      );

      return (
        <CanAccess
          key={item.key}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={item.key}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route ?? ""}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        t(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes."
        )
      );

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
      }
    } else {
      modal.confirm({
        icon: null,
        title: (
          <Typography.Title level={5}>
            {t("dialogs.logout.title")}
          </Typography.Title>
        ),
        content: (
          <Typography.Text>{t("dialogs.logout.content")}</Typography.Text>
        ),
        okText: t("buttons.agree"),
        cancelText: t("buttons.cancel"),
        onOk() {
          mutateLogout();
        },
      });
    }
  };

  const logout = isExistAuthentication && (
    <Menu.Item
      key="logout"
      onClick={() => handleLogout()}
      icon={<LogoutOutlined />}
    >
      {t("buttons.logout", "Logout")}
    </Menu.Item>
  );

  const dashboard = hasDashboard ? (
    <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
      <Link to={PATHS.dashboard}>{t("dashboard.title", "Dashboard")}</Link>
      {!collapsed && selectedKey === PATHS.dashboard && (
        <div className="ant-menu-tree-arrow" />
      )}
    </Menu.Item>
  ) : null;

  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        items,
        logout,
        collapsed,
      });
    }
    return (
      <>
        {dashboard}
        {items}
        {/* {logout} */}
      </>
    );
  };

  const renderMenu = () => {
    return (
      <>
        <Menu
          selectedKeys={selectedKey ? [selectedKey] : []}
          openKeys={openKeys ?? []}
          onOpenChange={setOpenKeys}
          theme="dark"
          mode="inline"
          style={{
            marginTop: "8px",
            border: "none",
          }}
          onClick={() => {
            setDrawerOpen(false);
            if (!breakpoint.lg) {
              setCollapsed(true);
            }
          }}
        >
          {renderSider()}
        </Menu>
      </>
    );
  };

  const renderDrawerSider = () => {
    return (
      <>
        <Drawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          placement="left"
          closable={false}
          width={DEFAULT_SIDER_WIDTH}
          bodyStyle={{
            padding: 0,
          }}
          maskClosable={true}
        >
          <Layout>
            <Layout.Sider
              width={DEFAULT_SIDER_WIDTH}
              style={{
                height: "100vh",
                overflow: "hidden",
                // backgroundColor: token.colorBgContainer,
                // borderRight: `1px solid ${token.colorBgElevated}`,
              }}
            >
              <div
                style={{
                  width: DEFAULT_SIDER_WIDTH,
                  padding: "0 16px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: SIDER_HEADER_HEIGHT,
                  // backgroundColor: token.colorBgElevated,
                }}
              >
                <RenderToTitle collapsed={false} title={<BrandWithDarkBg />} />
              </div>
              {renderMenu()}
            </Layout.Sider>
          </Layout>
        </Drawer>
        <Button
          style={drawerButtonStyles}
          size="large"
          onClick={() => setDrawerOpen(true)}
          icon={<BarsOutlined />}
        ></Button>
      </>
    );
  };

  if (isMobile) {
    return renderDrawerSider();
  }

  return (
    <Layout.Sider
      style={
        {
          // backgroundColor: token.colorBgContainer,
          // borderRight: `1px solid ${token.colorBgElevated}`,
        }
      }
      collapsible={false}
      collapsed={collapsed}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      collapsedWidth={DEFAULT_SIDER_COLLAPSED_WIDTH}
      width={DEFAULT_SIDER_WIDTH}
      breakpoint="lg"
      trigger={
        <Button
          type="text"
          style={{
            borderRadius: 0,
            height: "100%",
            width: "100%",
            // backgroundColor: token.colorBgElevated,
          }}
        >
          {collapsed ? (
            <RightOutlined
              style={{
                color: token.colorPrimary,
              }}
            />
          ) : (
            <LeftOutlined
              style={{
                color: token.colorPrimary,
              }}
            />
          )}
        </Button>
      }
    >
      <div
        style={{
          width: collapsed
            ? DEFAULT_SIDER_COLLAPSED_WIDTH
            : DEFAULT_SIDER_WIDTH,
          padding: collapsed ? "0" : "0 16px",
          display: "flex",
          justifyContent: collapsed ? "center" : "flex-start",
          alignItems: "center",
          height: collapsed
            ? SIDER_HEADER_COLLAPSED_HEIGHT
            : SIDER_HEADER_HEIGHT,
          // backgroundColor: token.colorBgElevated,
          fontSize: "14px",
        }}
      >
        <RenderToTitle collapsed={collapsed} title={<BrandWithDarkBg />} />
      </div>
      {renderMenu()}
    </Layout.Sider>
  );
};
