import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import React from "react";

import TextBox from "components/textBox";
import { API_RESOURCES, DATE_FORMAT } from "config";
import { IHoliday } from "interfaces";
import useUserRole from "hooks/useUserRole";

export const HolidayShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const { list } = useNavigation();
  const t = useTranslate();

  const { queryResult } = useShow<IHoliday, HttpError>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { hasAdminRole, hasManagerRole } = useUserRole();
  const canEditDelete = hasAdminRole || hasManagerRole;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={
        <>
          {resource?.canEdit && canEditDelete && (
            <EditButton disabled={isLoading} type="primary" />
          )}
          {resource?.meta?.canDelete && canEditDelete && (
            <DeleteButton
              disabled={isLoading}
              onSuccess={() => {
                list(API_RESOURCES.holidays);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("holidays.notifications.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("holidays.notifications.deleteSuccess"),
              }}
            />
          )}
        </>
      }
    >
      <Row gutter={[16, 24]}>
        <Col xs={24} md={12} lg={8}>
          <TextBox value={record?.name} label={t("holidays.fields.name")} />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={record?.date ? dayjs(record?.date).format(DATE_FORMAT) : ""}
            label={t("holidays.fields.date")}
          />
        </Col>

        <Col xs={24}>
          <TextBox
            valueStyles={{ minHeight: 100 }}
            value={record?.description}
            label={t("holidays.fields.description")}
          />
        </Col>
      </Row>
    </Show>
  );
};
