import dayjs, { Dayjs } from "dayjs";
import { ITimePeriod } from "interfaces";

export const convertDayjsToTimePeriod = (data?: Dayjs): ITimePeriod => {
  return {
    hour: data?.hour() ?? 0,
    minute: data?.minute() ?? 0,
    second: 0,
    nano: 0,
  };
};

export const convertTimePeriodToDayjs = (timePeriod?: ITimePeriod): Dayjs => {
  return dayjs()
    .set("hour", timePeriod?.hour ?? 0)
    .set("minute", timePeriod?.minute ?? 0)
    .set("second", timePeriod?.second ?? 0)
    .set("millisecond", timePeriod?.nano ?? 0);
};

export const formatTimePeriod = (timePeriod?: ITimePeriod): string => {
  const hour = timePeriod?.hour;
  const minute = timePeriod?.minute;
  return `${hour}:${minute}`;
};

export const convertDayjsToTimePeriodString = (data?: Dayjs): String => {
  const hour = String(data?.hour() ?? 0).padStart(2, "0");
  const minute = String(data?.minute() ?? 0).padStart(2, "0");
  const second = String(data?.second() ?? 0).padStart(2, "0");
  return `${hour}:${minute}:${second}`;
};

export const convertToTimePeriodStringDayjs = (data?: string): Dayjs | null => {
  if (!data) return null;
  const arr = data.split(":");
  if (arr.length !== 3) return null;

  const hour = parseInt(arr[0]);
  const minute = parseInt(arr[1]);
  const second = parseInt(arr[2]);
  return dayjs().set("hour", hour).set("minute", minute).set("second", second);
};

export const formatTimePeriodString = (data?: string): string | null => {
  if (!data) return null;

  const arr = data.split(":");
  if (arr.length !== 3) return data;

  const hour = arr[0];
  const minute = arr[1];
  return `${hour}:${minute}`;
};