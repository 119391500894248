import { Create, useForm } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import React from "react";

import { IUser, IUserRoleCode } from "interfaces";
import { DEFAULT_INPUT_MAX_LENGTH, ROLE_LIST } from "config";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IUser, HttpError>({
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("users.notifications.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("users.notifications.createSuccess"),
    },
  });

  const roles = Form.useWatch("roles", formProps?.form) as
    | IUserRoleCode[]
    | undefined;

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.fullName")}
              name={["fullName"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.fullNameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("users.placeholders.fullName")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.username")}
              name={["username"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.usernameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("users.placeholders.username")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.mobile")}
              name={["mobile"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.mobileRequired"),
                },
                {
                  validator: (_, value) => {
                    if (!value || isValidPhoneNumb(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("users.errors.mobileInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input placeholder={t("users.placeholders.mobile")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.email")}
              name={["email"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.emailRequired"),
                },
                {
                  type: "email",
                  message: t("users.errors.emailInvalid"),
                },
              ]}
            >
              <Input placeholder={t("users.placeholders.email")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.role")}
              name={["roles"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.roleRequired"),
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                // showSearch
                // filterOption={(input, option) =>
                //   fuzzySearch(
                //     toLowerCaseNonAccentVietnamese(String(option?.children ?? "")),
                //     toLowerCaseNonAccentVietnamese(input)
                //   )
                // }
                placeholder={t("users.placeholders.role")}
                onSelect={(value) => {
                  if (value === "STUDENT") {
                    formProps?.form?.setFieldValue("roles", ["STUDENT"]);
                  } else {
                    const _roles =
                      roles?.filter((it) => it !== "STUDENT") ?? [];
                    formProps?.form?.setFieldValue(
                      "roles",
                      _roles?.concat(value)
                    );
                  }
                }}
              >
                {ROLE_LIST(t)
                  .filter((role) => role.isCreatable)
                  .map((role) => (
                    <Select.Option key={role.value} value={role.value}>
                      {role.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.position")}
              name={["position"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("users.errors.positionRequired"),
              //   },
              // ]}
            >
              <Input
                placeholder={t("users.placeholders.position")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.password")}
              name={["password"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.passwordRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("users.placeholders.password")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
