import { PhoneOutlined } from "@ant-design/icons";
import {
  useApiUrl,
  useCustomMutation,
  useLink,
  useTranslate,
} from "@refinedev/core";
import { Button, Form, Input, Typography } from "antd";

import { UnauthenticatedLayout } from "components/layout";
import { IForgotPasswordTypes } from "interfaces";

const ForgotPassword = () => {
  const t = useTranslate();

  const [form] = Form.useForm();

  const Link = useLink();

  const apiUrl = useApiUrl();

  const { mutate: forgotPassword, isLoading } =
    useCustomMutation<IForgotPasswordTypes>();

  const handleForgotPassword = async ({ email }: IForgotPasswordTypes) => {
    forgotPassword({
      url: `${apiUrl}/auth/forgot-password`,
      method: "post",
      values: {
        email,
      },
      errorNotification: {
        type: "error",
        message: t("pages.forgotPassword.notification.forgotPasswordError"),
      },
      successNotification: {
        type: "success",
        message: t("pages.forgotPassword.notification.forgotPasswordSuccess"),
      },
    });
  };

  const formTitle = (
    <div style={{ textAlign: "center" }}>
      <Typography.Title level={2} style={{ margin: 0 }}>
        {t("pages.forgotPassword.title")}
      </Typography.Title>
      {/* <Typography.Text style={{ color: "#52575C" }}>
        {t("pages.forgotPassword.description")}
      </Typography.Text> */}
    </div>
  );

  const formContent = (
    <Form<IForgotPasswordTypes>
      layout="vertical"
      form={form}
      onFinish={(values) => handleForgotPassword(values)}
      requiredMark={false}
      style={{ width: "100%" }}
    >
      {/* <Form.Item
        name="email"
        label={t("pages.forgotPassword.fields.email")}
        rules={[
          {
            required: true,
            message: t("pages.forgotPassword.errors.emailRequired"),
          },
          {
            type: "email",
            message: t("pages.forgotPassword.errors.validEmail"),
          },
        ]}
      >
        <Input
          type="email"
          size="large"
          placeholder={t("pages.forgotPassword.placeholders.email")}
          prefix={<MailOutlined />}
        />
      </Form.Item> */}

      <Form.Item
        name="phone"
        // label={t("pages.forgotPassword.fields.phone")}
        rules={[
          {
            required: true,
            message: t("pages.forgotPassword.errors.phoneRequired"),
          },
        ]}
      >
        <Input
          size="large"
          placeholder={t("pages.forgotPassword.placeholders.phone")}
          prefix={<PhoneOutlined />}
        />
      </Form.Item>

      <Form.Item style={{ marginTop: "30px" }}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          block
        >
          {t("pages.forgotPassword.buttons.submit")}
        </Button>
      </Form.Item>

      <div style={{ marginTop: 8, textAlign: "center" }}>
        <Typography.Text style={{ fontSize: 12 }}>
          {t("pages.forgotPassword.buttons.haveAccount")}{" "}
          <Link to={"/login"}>{t("common.signin")}</Link>
        </Typography.Text>
      </div>
    </Form>
  );

  return (
    <UnauthenticatedLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          gap: 25,
          width: 400,
          maxWidth: "100%",
        }}
      >
        {formTitle}
        {formContent}
      </div>
    </UnauthenticatedLayout>
  );
};

export default ForgotPassword;
