import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_AVATAR_KEY,
  USER_ID_KEY,
  USER_NAME_KEY,
  USER_ROLE_KEY,
  USER_USERNAME_KEY,
} from "config";
import { IUserRoleCode } from "interfaces";

export function getUserRoles(): IUserRoleCode[] {
  try {
    const rolesString = localStorage.getItem(USER_ROLE_KEY);
    if (!rolesString) return [];

    return JSON.parse(rolesString);
  } catch (error) {
    return [];
  }
}

export function removeAllUserIdentities() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(USER_ID_KEY);
  localStorage.removeItem(USER_NAME_KEY);
  localStorage.removeItem(USER_USERNAME_KEY);
  localStorage.removeItem(USER_ROLE_KEY);
  localStorage.removeItem(USER_AVATAR_KEY);
}
