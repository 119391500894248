import {
  DateField,
  EditButton,
  MarkdownField,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Button, Space, Table } from "antd";
import React from "react";

import { CloudUploadOutlined } from "@ant-design/icons";
import { PageList } from "components/pages";

export const NotificationList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useTable<BaseRecord, HttpError>({
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;

  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  const apiUrl = useApiUrl();

  const { isRefetching: isRefetchingExport, refetch: refetchExport } =
    useCustom({
      url: `${apiUrl}/export/users`,
      method: "get",
      queryOptions: {
        enabled: false,
        retry: 0,
      },
      errorNotification(error: any) {
        return {
          type: "error",
          message:
            error?.message ?? t("notifications.notifications_.exportError"),
        };
      },
      successNotification: {
        type: "success",
        message: t("notifications.notifications_.exportSuccess"),
      },
    });

  return (
    <PageList
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[
        <Button
          icon={<CloudUploadOutlined />}
          loading={isRefetchingExport}
          disabled={isRefetchingExport}
          onClick={() => refetchExport()}
        >
          {t("buttons.export")}
        </Button>,
      ]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column
          dataIndex="content"
          title="Content"
          render={(value: any) => (
            <MarkdownField value={value?.slice(0, 80) + "..."} />
          )}
        />
        <Table.Column dataIndex="status" title="Status" />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </PageList>
  );
};
