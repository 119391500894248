import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DatePicker } from "components/datepicker";
import {
  API_RESOURCES,
  DEFAULT_INPUT_MAX_LENGTH,
  DEFAULT_TEXT_AREA_MAX_LENGTH,
} from "config";
import { IClassPeriod, INormalClass, IUser } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { StudentTable } from "./private/studentTable";
import { formatClassPeriod, formatUser } from "utils/modelHelper";

export const NormalClassEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();
  const { list } = useNavigation();

  const {
    formProps: { onFinish: _, form: formEditNormalClass, ...restFormProps },
    saveButtonProps,
    queryResult,
  } = useForm<INormalClass, HttpError>({
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("normal_class.notifications.editError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("normal_class.notifications.editSuccess"),
    },
  });

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  const apiUrl = useApiUrl();
  const { setWarnWhen } = useWarnAboutChange();
  const [studentList, setStudentList] = useState<IUser[]>([]);

  const { mutate: updateNormalClass } = useCustomMutation<INormalClass>();

  const handleUpdateNormalClass = async (values: any) => {
    updateNormalClass(
      {
        url: `${apiUrl}/normal_class/update`,
        method: "put",
        values: {
          ...values,
          id: queryResult?.data?.data?.id,
          description: values?.description ?? "",
          teachers: values.teacherId ? [values.teacherId] : [],
          teacherId: undefined,
          schoolYear: values?.schoolYear
            ? String(dayjs(values.schoolYear).get("year"))
            : null,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("normal_class.notifications.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("normal_class.notifications.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.normalClasses);
          }, 100);
        },
      }
    );
  };

  const {
    selectProps: {
      showSearch: showSearchClassPeriod,
      onSearch: onSearchClassPeriod,
      ...classPeriodSelectProps
    },
    queryResult: classPeriodQueryResult,
  } = useSelect<IClassPeriod, HttpError>({
    resource: API_RESOURCES.classPeriods,
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
      mode: "server",
    },
  });

  const classPeriodSelectOptions = useMemo(
    () =>
      classPeriodQueryResult?.data?.data.map((classPeriod) => ({
        value: classPeriod?.id,
        label: formatClassPeriod(classPeriod),
      })),
    [classPeriodQueryResult?.data?.data]
  );

  const {
    selectProps: {
      showSearch: showSearchTeacher,
      onSearch: onSearchTeacher,
      ...teacherSelectProps
    },
    queryResult: teacherQueryResult,
  } = useSelect<IUser, HttpError>({
    resource: `${API_RESOURCES.users}/teachers`,
    optionLabel: "fullName",
    pagination: false,
  });

  const teacherSelectOptions = useMemo(
    () =>
      teacherQueryResult?.data?.data
        .sort((a, b) => (a.fullName ?? "").localeCompare(b.fullName ?? ""))
        .map((teacher) => ({
          value: teacher?.id,
          label: formatUser(teacher),
        })),
    [teacherQueryResult?.data?.data]
  );

  const updateStudentList = useCallback(
    (newStudentList: IUser[]) => {
      setStudentList(newStudentList);
      formEditNormalClass?.setFieldsValue({
        students: newStudentList.map((item) => item.id),
      });
    },
    [formEditNormalClass]
  );

  const initialStudentList = useMemo(
    () => queryResult?.data?.data?.students || [],
    [queryResult]
  );

  useEffect(() => {
    updateStudentList(
      initialStudentList.map((student, index) => ({
        ...student,
        key: index + 1,
      }))
    );
  }, [initialStudentList, updateStudentList]);

  return (
    <Edit
      isLoading={isProcessing}
      headerButtons={
        <>
          <ShowButton />
        </>
      }
      footerButtons={
        <>
          {resource?.meta?.canDelete && (
            <DeleteButton
              disabled={isProcessing}
              onSuccess={() => {
                list(API_RESOURCES.normalClasses);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("normal_class.notifications.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("normal_class.notifications.deleteSuccess"),
              }}
            />
          )}
          <SaveButton disabled={isProcessing} {...saveButtonProps} />
        </>
      }
    >
      <Form
        {...restFormProps}
        form={formEditNormalClass}
        layout="vertical"
        initialValues={{
          ...restFormProps.initialValues,
          schoolYear: restFormProps.initialValues?.schoolYear
            ? dayjs(restFormProps.initialValues?.schoolYear)
            : undefined,
          classPeriodId: restFormProps?.initialValues?.classPeriod?.id,
          teacherId: restFormProps?.initialValues?.teachers?.[0]?.id,
        }}
        onFinish={(values) => handleUpdateNormalClass(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("normal_class.fields.schoolYear")}
              name={["schoolYear"]}
              rules={[
                {
                  required: true,
                  message: t("normal_class.errors.schoolYearRequired"),
                },
              ]}
            >
              <DatePicker
                placeholder={t("normal_class.placeholders.schoolYear")}
                style={{ width: "100%" }}
                picker="year"
                disabledDate={(date) =>
                  date < dayjs(new Date()).startOf("years").startOf("days")
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("normal_class.fields.code")}
              name={["code"]}
              rules={[
                {
                  required: true,
                  message: t("normal_class.errors.codeRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("normal_class.placeholders.code")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("normal_class.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("normal_class.errors.nameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("normal_class.placeholders.name")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("normal_class.fields.classPeriod")}
              name={["classPeriodId"]}
              rules={[
                {
                  required: true,
                  message: t("normal_class.errors.classPeriodRequired"),
                },
              ]}
            >
              <Select
                allowClear
                placeholder={t("normal_class.placeholders.classPeriod")}
                {...classPeriodSelectProps}
                options={classPeriodSelectOptions}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("normal_class.fields.teacher")}
              name={["teacherId"]}
              rules={[
                {
                  required: true,
                  message: t("normal_class.errors.teacherRequired"),
                },
              ]}
            >
              <Select
                allowClear
                placeholder={t("normal_class.placeholders.teacher")}
                {...teacherSelectProps}
                options={teacherSelectOptions}
                showSearch
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item name={"students"}>
              <Input hidden />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("normal_class.fields.description")}
              name={["description"]}
            >
              <Input.TextArea
                rows={4}
                placeholder={t("normal_class.placeholders.description")}
                maxLength={DEFAULT_TEXT_AREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <StudentTable
              studentList={studentList}
              onStudentListChanged={(newStudentList) => {
                updateStudentList(newStudentList);
              }}
              hasAdd
              hasDelete
            />
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
