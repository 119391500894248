import { PageHeader } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Col, Row, Table, Typography } from "antd";
import dayjs from "dayjs";

import TextBox from "components/textBox";
import { DATE_FORMAT } from "config";
import { ITimeTable } from "interfaces";

interface ShowTimeTableContentProps {
  upsertTimeTableValues: ITimeTable | null;
}

const ShowTimeTableContent: React.FC<ShowTimeTableContentProps> = ({
  upsertTimeTableValues,
}) => {
  const t = useTranslate();
  const students = upsertTimeTableValues?.students;
  const classPeriod = upsertTimeTableValues?.classPeriod;
  // const apiUrl = useApiUrl();

  // const { isRefetching, isFetching, isLoading, data } = useCustom<IUser[]>({
  //   url: `${apiUrl}/timetable/student_in_timetable/${upsertTimeTableValues?.id}`,
  //   method: "get",
  //   queryOptions: {
  //     enabled: Boolean(upsertTimeTableValues?.id),
  //   },
  // });

  return (
    <>
      <Row gutter={[16, 24]} style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Col xs={24} sm={12} md={8}>
          <TextBox
            value={
              upsertTimeTableValues?.date
                ? dayjs(new Date(upsertTimeTableValues?.date)).format(
                    DATE_FORMAT
                  )
                : "--"
            }
            label={t("extra_class.timeTables.fields.date")}
          />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <TextBox
            value={
              classPeriod
                ? `${classPeriod?.name} (${classPeriod?.startTime} - ${classPeriod?.endTime})`
                : "--"
            }
            label={t("extra_class.timeTables.fields.classPeriod")}
          />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <TextBox
            value={
              upsertTimeTableValues?.teachers
                ?.map((it) => it.fullName)
                ?.join(", ") ?? "--"
            }
            label={t("extra_class.timeTables.fields.teacher")}
          />
        </Col>
      </Row>

      <PageHeader
        backIcon={null}
        style={{ marginTop: 16, padding: 0, paddingLeft: 10, paddingRight: 10 }}
        title={
          <Typography.Text
            style={{
              fontSize: 15,
              fontWeight: 500,
            }}
          >
            {t("extra_class.timeTables.participants.title")}
          </Typography.Text>
        }
      />

      <Table rowKey="id" scroll={{ x: 300 }} dataSource={students}>
        <Table.Column<ITimeTable>
          dataIndex="key"
          key="key"
          title={t("extra_class.timeTables.participants.fields.idx")}
          render={(_, record, index) => {
            return index + 1;
          }}
        />
        <Table.Column<ITimeTable>
          dataIndex="fullName"
          key="fullName"
          title={t("extra_class.timeTables.participants.fields.fullName")}
        />
        <Table.Column<ITimeTable>
          dataIndex="username"
          key="username"
          title={t("extra_class.timeTables.participants.fields.username")}
        />
        <Table.Column<ITimeTable>
          dataIndex="email"
          key="email"
          title={t("extra_class.timeTables.participants.fields.email")}
        />
        <Table.Column<ITimeTable>
          dataIndex="classCode"
          key="classCode"
          title={t("extra_class.timeTables.participants.fields.className")}
        />
        <Table.Column<ITimeTable>
          dataIndex="teacher"
          key="teacher"
          title={t("extra_class.timeTables.participants.fields.teacher")}
          render={(teacher: string) => teacher}
        />
        <Table.Column<ITimeTable>
          dataIndex="currentAttendanceStatus"
          key="currentAttendanceStatus"
          title={t(
            "extra_class.timeTables.participants.fields.currentAttendanceStatus"
          )}
          render={(currentAttendanceStatus) =>
            t(`common.currentAttendanceStatus_${currentAttendanceStatus}`)
          }
        />
      </Table>
    </>
  );
};

export default ShowTimeTableContent;
