import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Row } from "antd";
import React from "react";

import TextBox from "components/textBox";
import { API_RESOURCES } from "config";
import { IClassPeriod } from "interfaces";
import { formatTimePeriodString } from "utils/timeHelpers";
import useUserRole from "hooks/useUserRole";

export const ClassPeriodShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const { list } = useNavigation();
  const t = useTranslate();

  const { queryResult } = useShow<IClassPeriod, HttpError>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { hasAdminRole, hasManagerRole } = useUserRole();
  const canEditDelete = hasAdminRole || hasManagerRole;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={
        <>
          {resource?.canEdit && canEditDelete && (
            <EditButton disabled={isLoading} type="primary" />
          )}
          {resource?.meta?.canDelete && canEditDelete && (
            <DeleteButton
              disabled={isLoading}
              onSuccess={() => {
                list(API_RESOURCES.classPeriods);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("class_period.notifications.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("class_period.notifications.deleteSuccess"),
              }}
            />
          )}
        </>
      }
    >
      <Row gutter={[16, 24]}>
        <Col xs={24} md={12} lg={8}>
          <TextBox value={record?.code} label={t("class_period.fields.code")} />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox value={record?.name} label={t("class_period.fields.name")} />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={
              record?.startTime && record?.endTime
                ? `${formatTimePeriodString(
                    record?.startTime
                  )} - ${formatTimePeriodString(record?.endTime)}`
                : ""
            }
            label={t("class_period.fields.time")}
          />
        </Col>

        <Col xs={24}>
          <TextBox
            valueStyles={{ minHeight: 100 }}
            value={record?.description}
            label={t("class_period.fields.description")}
          />
        </Col>
      </Row>
    </Show>
  );
};
