import { CloudDownloadOutlined } from "@ant-design/icons";
import { Show, useTable } from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useApiUrl,
  useCustom,
  useTranslate,
} from "@refinedev/core";
import { App, Button, Col, Row, Table, Typography, theme } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import axiosInstance from "axiosInstance";
import { RangePicker } from "components/datepicker";
import TextBox from "components/textBox";
import { DATE_FORMAT } from "config";
import {
  ITimeTableReportStudent,
  ITimeTableReportStudentFlat,
  IUserExtended,
} from "interfaces";
import { downloadData } from "utils/domHelper";
import { formatClassPeriod } from "utils/modelHelper";

const DEFAULT_RANGE_DATE_AMOUNT = 30;

export const ReportStudentsShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const t = useTranslate();
  const { notification } = App.useApp();
  const { token } = theme.useToken();

  const { tableProps, tableQueryResult, filters, setFilters } = useTable<
    ITimeTableReportStudent,
    HttpError
  >({
    resource: `reports/student/timetable/${id}`,
    syncWithLocation: true,
    queryOptions: {
      enabled: Boolean(id),
    },
    pagination: {
      mode: "server",
    },
    filters: {
      initial: [
        {
          field: "startDate",
          operator: "eq",
          value: dayjs()
            .subtract(DEFAULT_RANGE_DATE_AMOUNT, "days")
            .startOf("days")
            .toISOString(),
        },
        {
          field: "endDate",
          operator: "eq",
          value: dayjs().endOf("days").toISOString(),
        },
      ],
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const startDate = useMemo(
    () => getDefaultFilter("startDate", filters, "eq"),
    [filters]
  );
  const endDate = useMemo(
    () => getDefaultFilter("endDate", filters, "eq"),
    [filters]
  );
  const handleRangeDateChange = useCallback(
    (startDate: string | null, endDate: string | null) => {
      if (startDate && endDate) {
        setFilters(() => {
          const finalFilters: CrudFilters = ([] as CrudFilters)
            .concat({
              field: "startDate",
              operator: "eq",
              value: startDate,
            })
            .concat({ field: "endDate", operator: "eq", value: endDate });
          return finalFilters;
        });
      }
    },
    [setFilters]
  );

  const isTableLoading =
    tableQueryResult?.isFetching ||
    tableQueryResult?.isRefetching ||
    tableQueryResult?.isLoading;

  const dataSource = useMemo(() => {
    if (!tableProps?.dataSource) {
      return [];
    }

    const dataSource: ITimeTableReportStudentFlat[] = [];
    let idx = 0;

    for (const it of tableProps?.dataSource) {
      const date = it.date;
      const reportTimetableStatuses = it.reportTimetableStatus ?? [];

      for (const timeTable of reportTimetableStatuses) {
        dataSource.push({
          idx: ++idx,
          date,
          classPeriod: timeTable.classPeriod,
          status: timeTable.status,
        });
      }
    }

    return dataSource;
  }, [tableProps.dataSource]);

  const apiUrl = useApiUrl();
  const { data: userData, isLoading: isUserLoading } = useCustom<IUserExtended>(
    {
      url: `${apiUrl}/reports/student/info/${id}`,
      method: "get",
    }
  );
  const userRecord = userData?.data;

  const [isRefetchingExport, setIsRefetchingExport] = useState(false);

  const refetchExport = async () => {
    try {
      setIsRefetchingExport(true);
      const { data } = await axiosInstance.get(
        `${apiUrl}/reports/student/export/${id}`,
        {
          responseType: "blob",
        }
      );
      downloadData(data, t("reports_students.exports.fileName"));
      notification.success({
        message: t("reports_students.notifications.exportSuccess"),
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.message ?? t("reports_students.notifications.exportError"),
      });
    } finally {
      setIsRefetchingExport(false);
    }
  };

  return (
    <Show
      isLoading={isTableLoading || isUserLoading}
      headerButtons={[
        <Button
          key="download"
          icon={<CloudDownloadOutlined />}
          loading={isRefetchingExport}
          disabled={isRefetchingExport}
          onClick={() => refetchExport()}
        >
          {t("buttons.export")}
        </Button>,
      ]}
    >
      <Row gutter={[16, 24]} style={{ marginBottom: 32 }}>
        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={userRecord?.fullName ?? "--"}
            label={t("users.fields.fullName")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={userRecord?.username ?? "--"}
            label={t("users.fields.username")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={userRecord?.mobile ?? "--"}
            label={t("users.fields.mobile")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={userRecord?.email ?? "--"}
            label={t("users.fields.email")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={userRecord?.position ?? "--"}
            label={t("users.fields.position")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={userRecord?.teacher ?? "--"}
            label={t("users.fields.teacher")}
          />
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Typography.Title level={5} style={{ color: token.colorPrimary }}>
          {t("reports_students.details.title")}
        </Typography.Title>

        <RangePicker
          allowClear={false}
          format={DATE_FORMAT}
          value={[
            startDate ? dayjs(startDate) : null,
            endDate ? dayjs(endDate) : null,
          ]}
          onCalendarChange={(rangeValue) => {
            const startDate = rangeValue?.[0]?.toISOString() ?? null;
            const endDate = rangeValue?.[1]?.toISOString() ?? null;
            handleRangeDateChange(startDate, endDate);
          }}
        />
      </div>

      <Table {...tableProps} dataSource={dataSource} rowKey="idx">
        <Table.Column
          dataIndex="idx"
          title={t("reports_students.details.fields.idx")}
        />
        <Table.Column
          dataIndex="date"
          title={t("reports_students.details.fields.date")}
          render={(date) => (date ? dayjs(date).format(DATE_FORMAT) : "")}
        />
        <Table.Column
          dataIndex="classPeriod"
          title={t("reports_students.details.fields.classPeriod")}
          render={(classPeriod) => formatClassPeriod(classPeriod)}
        />
        <Table.Column
          dataIndex="status"
          title={t("reports_students.details.fields.currentAttendanceStatus")}
          render={(status) => t(`common.currentAttendanceStatus_${status}`)}
        />
      </Table>
    </Show>
  );
};
