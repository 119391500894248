import { LockOutlined } from "@ant-design/icons";
import { PageHeader } from "@refinedev/antd";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Button, Card, Col, Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";

import TextBox from "components/textBox";
import { PATHS, ROLE_LIST } from "config";
import { IUser } from "interfaces";

const AccountInfo: React.FC = () => {
  const navigate = useNavigate();
  const t = useTranslate();

  const apiUrl = useApiUrl();
  const { data, isLoading } = useCustom<IUser>({
    url: `${apiUrl}/users/me`,
    method: "get",
  });

  const user = data?.data;
  // const { data: user } = useGetIdentity<IUserIdentity>();

  return (
    <PageHeader
      title={t("pages.accountInfo.title")}
      extra={
        <>
          <Button
            icon={<LockOutlined />}
            onClick={() => navigate(PATHS.updatePassword)}
          >
            {t("pages.accountInfo.actions.changePassword")}
          </Button>
        </>
      }
    >
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Row gutter={[16, 24]}>
            <Col xs={24} md={12}>
              <TextBox
                value={user?.fullName ?? "--"}
                label={t("pages.accountInfo.fields.fullName")}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                value={user?.username ?? "--"}
                label={t("pages.accountInfo.fields.username")}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                value={user?.mobile ?? "--"}
                label={t("pages.accountInfo.fields.mobile")}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                value={user?.email ?? "--"}
                label={t("pages.accountInfo.fields.email")}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                value={user?.position ?? "--"}
                label={t("pages.accountInfo.fields.position")}
              />
            </Col>

            <Col xs={24} md={12}>
              <TextBox
                value={
                  ROLE_LIST(t)
                    .filter((role) =>
                      user?.roles?.find((it) => it.code === role.value)
                    )
                    ?.map((role) => role.label)
                    ?.join(", ") ?? "--"
                }
                label={t("pages.accountInfo.fields.role")}
              />
            </Col>
          </Row>
        </Card>
      </Spin>
    </PageHeader>
  );
};

export default AccountInfo;
