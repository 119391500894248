import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { App, Button, Form, Input, Typography } from "antd";

import { UnauthenticatedLayout } from "components/layout";
import { IForgotPasswordTypes } from "interfaces";

const RemoveAccount = () => {
  const t = useTranslate();
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  const formTitle = (
    <div style={{ textAlign: "center" }}>
      <Typography.Title level={2} style={{ margin: 0, marginBottom: 10 }}>
        {t("pages.removeAccount.title")}
      </Typography.Title>
      <Typography.Text
        style={{ color: "#52575C", fontSize: 13, marginTop: 12 }}
      >
        {t("pages.removeAccount.description")}
      </Typography.Text>
    </div>
  );

  const formContent = (
    <Form<IForgotPasswordTypes>
      layout="vertical"
      form={form}
      onFinish={(values) => {
        // console.log({ values });
        notification.success({
          message: t("pages.removeAccount.notification.removeAccountSuccess"),
        });
        // navigate(PATHS.home);
        form?.resetFields();
      }}
      requiredMark={false}
      style={{ width: "100%" }}
    >
      <Form.Item
        name="email"
        // label={t("pages.removeAccount.fields.email")}
        rules={[
          {
            required: true,
            message: t("pages.removeAccount.errors.emailRequired"),
          },
          {
            type: "email",
            message: t("pages.removeAccount.errors.validEmail"),
          },
        ]}
      >
        <Input
          type="email"
          size="large"
          placeholder={t("pages.removeAccount.placeholders.email")}
          prefix={<MailOutlined />}
        />
      </Form.Item>

      <Form.Item
        name="phone"
        // label={t("pages.removeAccount.fields.phone")}
        rules={[
          {
            required: true,
            message: t("pages.removeAccount.errors.phoneRequired"),
          },
        ]}
      >
        <Input
          size="large"
          placeholder={t("pages.removeAccount.placeholders.phone")}
          prefix={<PhoneOutlined />}
        />
      </Form.Item>

      <Form.Item style={{ marginTop: "30px" }}>
        <Button type="primary" size="large" htmlType="submit" block>
          {t("pages.removeAccount.buttons.submit")}
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <UnauthenticatedLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          gap: 25,
          width: 500,
          maxWidth: "100%",
        }}
      >
        {formTitle}
        {formContent}
      </div>
    </UnauthenticatedLayout>
  );
};

export default RemoveAccount;
