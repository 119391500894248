import { useTranslate } from "@refinedev/core";
import { Space } from "antd";
import { useMemo } from "react";

import { ITimeTableReportExtraClass } from "interfaces";
import CommonInfo from "./CommonInfo";
import StudentTable from "./StudentTable";

interface TimeTableReportExtraClassProps {
  record: ITimeTableReportExtraClass;
}

const TimeTableReportExtraClass: React.FC<TimeTableReportExtraClassProps> = ({
  record,
}) => {
  const t = useTranslate();

  const absentedStudents = useMemo(() => {
    return [
      ...(record?.absentedStudents ?? []),
      ...(record?.absentedWithNoticeStudents ?? []),
      ...(record?.lateStudents ?? []),
      ...(record?.otherReasonStudents ?? []),
    ]
      .map((it) => ({
        ...it,
        reason: t(
          `common.currentAttendanceStatus_${it.currentAttendanceStatus}`
        ),
      }))
      .sort((a, b) => (a.fullName ?? "").localeCompare(b.fullName ?? ""));
  }, [
    record?.absentedStudents,
    record?.absentedWithNoticeStudents,
    record?.lateStudents,
    record?.otherReasonStudents,
    t,
  ]);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <CommonInfo record={record} />
      <StudentTable
        studentList={record?.attendedStudents}
        title={t("reports_extra_classes.details.attendedStudents")}
        titleColor="#00748F"
      />
      <StudentTable
        studentList={record?.notProcessStudents}
        title={t("reports_extra_classes.details.notProcessStudents")}
        titleColor="#0084FF"
      />
      <StudentTable
        studentList={absentedStudents}
        title={t("reports_extra_classes.details.absentedStudents")}
        titleColor="#FF4747"
        withReason
      />
      {/* <StudentTable
        studentList={record?.absentedStudents}
        title={t("reports_extra_classes.details.absentedStudents")}
        titleColor="#FF4747"
      />
      <StudentTable
        studentList={record?.lateStudents}
        title={t("reports_extra_classes.details.lateStudents")}
        titleColor="#1C2634"
      />
      <StudentTable
        studentList={record?.otherReasonStudents}
        title={t("reports_extra_classes.details.otherReasonStudents")}
        titleColor="#d48806"
      /> */}
    </Space>
  );
};

export default TimeTableReportExtraClass;
