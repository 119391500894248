export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
}

export function toArray(value: any) {
  return Array.isArray(value) ? value : [value];
}

export function getFileExtension(filename: string) {
  return filename.substring(filename.lastIndexOf(".") + 1);
}
