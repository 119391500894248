import { EditOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useApiUrl, useCustomMutation, useTranslate } from "@refinedev/core";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { DEFAULT_INPUT_MAX_LENGTH } from "config";
import React from "react";

interface ChangePasswordModalBtnProps {
  userId?: string;
}

const ChangePasswordModalBtn: React.FC<ChangePasswordModalBtnProps> = ({
  userId,
}) => {
  const t = useTranslate();

  const {
    modalProps,
    formProps: { onFinish: _, form, ...restFormProps },
    show: showChangePassword,
    close: closeChangePassword,
  } = useModalForm({
    action: "create",
    submitOnEnter: true,
    warnWhenUnsavedChanges: false,
  });

  const { mutate: updatePassword, isLoading } = useCustomMutation();

  const apiUrl = useApiUrl();

  const handleChangePassword = async ({ newPass }: { newPass?: string }) => {
    updatePassword(
      {
        url: `${apiUrl}/users/change_passwd`,
        method: "post",
        values: {
          userId,
          newPass,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ??
              t("users.changePassword.notifications.changePassError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("users.changePassword.notifications.changePassSuccess"),
        },
      },
      {
        onSuccess() {
          closeChangePassword();
        },
      }
    );
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => showChangePassword()}
        icon={<EditOutlined />}
      >
        {t("users.changePassword.action")}
      </Button>

      <Modal
        {...modalProps}
        title={t("users.changePassword.title")}
        width={500}
        onCancel={() => closeChangePassword()}
        onOk={() =>
          form
            ?.validateFields()
            .then((values) => handleChangePassword(values))
            .catch((error) => console.log("error", error))
        }
        okButtonProps={{
          loading: isLoading,
        }}
      >
        <Form
          {...restFormProps}
          form={form}
          layout="vertical"
          initialValues={{
            newPass: undefined,
          }}
          onFinish={(values) => {
            handleChangePassword(values);
          }}
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                label={t("users.changePassword.fields.password")}
                name={["newPass"]}
                rules={[
                  {
                    required: true,
                    message: t("users.changePassword.errors.passwordRequired"),
                  },
                ]}
              >
                <Input.Password
                  placeholder={t("users.changePassword.placeholders.password")}
                  autoComplete="new-password"
                  maxLength={DEFAULT_INPUT_MAX_LENGTH}
                  showCount
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t("users.changePassword.fields.confirmPassword")}
                name={["confirmPassword"]}
                dependencies={["newPass"]}
                rules={[
                  {
                    required: true,
                    message: t(
                      "users.changePassword.errors.confirmPasswordRequired"
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPass") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t(
                            "users.changePassword.errors.confirmPasswordNotMatch"
                          )
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={t("users.changePassword.placeholders.password")}
                  autoComplete="new-password"
                  maxLength={DEFAULT_INPUT_MAX_LENGTH}
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModalBtn;
