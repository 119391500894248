import { LayoutProps } from "@refinedev/core";
import { Layout as AntdLayout, Grid } from "antd";
import React from "react";

import { Footer as DefaultFooter } from "../footer";
import { Header as DefaultHeader } from "../header";
import { OffLayoutArea as DefaultOffLayoutArea } from "../offLayoutArea";
import { Sider as DefaultSider } from "../sider";

export const AuthenticatedLayout: React.FC<LayoutProps> = ({
  children,
  Sider = DefaultSider,
  Header = DefaultHeader,
  Footer = DefaultFooter,
  OffLayoutArea = DefaultOffLayoutArea,
}) => {
  const breakpoint = Grid.useBreakpoint();
  const SiderToRender = Sider ?? DefaultSider;
  const HeaderToRender = Header ?? DefaultHeader;
  const isSmall = typeof breakpoint.sm === "undefined" ? true : breakpoint.sm;

  return (
    <AntdLayout style={{ minHeight: "100vh" }}>
      <SiderToRender />
      <AntdLayout>
        <HeaderToRender />
        <AntdLayout.Content>
          <div
            style={{
              minHeight: 360,
              padding: isSmall ? 24 : 12,
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};
