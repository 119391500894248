import { useSelect } from "@refinedev/antd";
import { HttpError, useTranslate } from "@refinedev/core";
import { Col, Row, Select } from "antd";
import { useMemo } from "react";

import WithLabel from "components/withLabel";
import { API_RESOURCES } from "config";
import { IUser } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { formatUser } from "utils/modelHelper";

interface AddStudentContentProps {
  studentList: IUser[];
  upsertStudentValues: IUser[] | undefined;
  setUpsertStudentValues: (upsertStudentValues: IUser[] | undefined) => void;
}

const AddStudentContent: React.FC<AddStudentContentProps> = ({
  studentList,
  upsertStudentValues,
  setUpsertStudentValues,
}) => {
  const t = useTranslate();

  const {
    selectProps: {
      showSearch: showSearchStudent,
      onSearch: onSearchStudent,
      ...studentSelectProps
    },
    queryResult: studentQueryResult,
  } = useSelect<IUser, HttpError>({
    resource: `${API_RESOURCES.users}/normal_students`,
    optionLabel: "fullName",
    pagination: false,
  });

  const filterStudentSelectOptions = useMemo(() => {
    const studentData = studentQueryResult?.data?.data.sort((a, b) =>
      (a.fullName ?? "")?.localeCompare(b.fullName ?? "")
    );

    if (!studentList.length) {
      return studentData?.map((student) => ({
        value: student?.id,
        label: formatUser(student),
      }));
    }

    return studentData
      ?.filter((opt) => !studentList.find((it) => it.id === opt.id))
      .map((student) => ({
        value: student?.id,
        label: formatUser(student),
      }));
  }, [studentList, studentQueryResult?.data?.data]);

  return (
    <Row gutter={[16, 24]} style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Col xs={24}>
        <WithLabel
          label={t("normal_class.participants.fields.fullName")}
          labelStyles={{ color: "#000000D9" }}
          hasRequiredMark
        >
          <Select
            {...studentSelectProps}
            placeholder={t("normal_class.participants.placeholders.fullName")}
            options={filterStudentSelectOptions}
            labelInValue
            allowClear
            mode="multiple"
            maxTagCount={6}
            value={
              upsertStudentValues
                ? (upsertStudentValues.map((it) => ({
                    value: it?.id ?? "",
                    label: formatUser(it) ?? "",
                  })) as any)
                : undefined
            }
            onChange={(items) => {
              setUpsertStudentValues(
                Array.isArray(items)
                  ? studentQueryResult?.data?.data?.filter((student) =>
                      items.find((it) => it.value === student.id)
                    )
                  : undefined
              );
            }}
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) =>
              fuzzySearch(
                toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                toLowerCaseNonAccentVietnamese(input)
              )
            }
          />
        </WithLabel>
      </Col>
    </Row>
  );
};

export default AddStudentContent;
