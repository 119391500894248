import { useGetIdentity } from "@refinedev/core";
import { IUserIdentity, IUserRoleCode } from "interfaces";
import { useMemo } from "react";

export default function useUserRole() {
  const { data: user } = useGetIdentity<IUserIdentity>();

  const hasAdminRole = useMemo(() => {
    return !!user?.roles?.find((role) => (role as IUserRoleCode) === "ADMIN");
  }, [user]);

  const hasManagerRole = useMemo(() => {
    return !!user?.roles?.find((role) => (role as IUserRoleCode) === "MANAGER");
  }, [user]);

  const hasTeacherRole = useMemo(() => {
    return !!user?.roles?.find((role) => (role as IUserRoleCode) === "TEACHER");
  }, [user]);

  const hasStudentRole = useMemo(() => {
    return !!user?.roles?.find((role) => (role as IUserRoleCode) === "STUDENT");
  }, [user]);

  return {
    hasAdminRole,
    hasManagerRole,
    hasTeacherRole,
    hasStudentRole,
  };
}
