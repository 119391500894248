import {
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  useForm,
} from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useGetIdentity,
  useNavigation,
  useResource,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import React from "react";

import { API_RESOURCES, DEFAULT_INPUT_MAX_LENGTH, ROLE_LIST } from "config";
import { IUser, IUserIdentity, IUserRoleCode } from "interfaces";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";
import ChangePasswordModalBtn from "./private/ChangePasswordModalBtn";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();
  const { list } = useNavigation();

  const {
    formProps: { onFinish: _, ...restFormProps },
    saveButtonProps,
    queryResult,
  } = useForm<IUser, HttpError>({
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("users.notifications.editError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("users.notifications.editSuccess"),
    },
  });

  const { data: user } = useGetIdentity<IUserIdentity>();

  const isMe = user?.username === queryResult?.data?.data?.username;
  const isAdmin = Boolean(
    queryResult?.data?.data?.roles?.find((it) => it.code === "ADMIN")
  );

  const isProcessing =
    queryResult?.isFetching ??
    queryResult?.isRefetching ??
    queryResult?.isLoading;

  const apiUrl = useApiUrl();
  const { setWarnWhen } = useWarnAboutChange();

  const { mutate: updateUser } = useCustomMutation<IUser>();

  const handleUpdateUser = async (values: IUser) => {
    updateUser(
      {
        url: `${apiUrl}/users/update`,
        method: "put",
        values: {
          ...values,
          id: queryResult?.data?.data?.id,
        },
        errorNotification(error) {
          return {
            type: "error",
            message: error?.message ?? t("users.notifications.editError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("users.notifications.editSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.users);
          }, 100);
        },
      }
    );
  };

  const roles = Form.useWatch("roles", restFormProps?.form) as
    | IUserRoleCode[]
    | undefined;

  return (
    <Edit
      isLoading={isProcessing}
      headerButtons={
        <>
          {!isMe && !isAdmin && (
            <ChangePasswordModalBtn userId={queryResult?.data?.data?.id} />
          )}
          <ShowButton />
        </>
      }
      footerButtons={
        <>
          {resource?.meta?.canDelete && !isMe && !isAdmin && (
            <DeleteButton
              disabled={isProcessing}
              onSuccess={() => {
                list(API_RESOURCES.users);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("users.notifications.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("users.notifications.deleteSuccess"),
              }}
            />
          )}
          <SaveButton disabled={isProcessing} {...saveButtonProps} />
        </>
      }
    >
      <Form
        {...restFormProps}
        layout="vertical"
        initialValues={{
          ...restFormProps.initialValues,
          roles: queryResult?.data?.data?.roles?.map((role) => role.code),
        }}
        onFinish={(values) => handleUpdateUser(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.fullName")}
              name={["fullName"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.fullNameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("users.placeholders.fullName")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.username")}
              name={["username"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.usernameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("users.placeholders.username")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.mobile")}
              name={["mobile"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.mobileRequired"),
                },
                {
                  validator: (_, value) => {
                    if (!value || isValidPhoneNumb(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("users.errors.mobileInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input placeholder={t("users.placeholders.mobile")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.email")}
              name={["email"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.emailRequired"),
                },
                {
                  type: "email",
                  message: t("users.errors.emailInvalid"),
                },
              ]}
            >
              <Input placeholder={t("users.placeholders.email")} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.role")}
              name={["roles"]}
              rules={[
                {
                  required: true,
                  message: t("users.errors.roleRequired"),
                },
              ]}
            >
              <Select
                // labelInValue
                mode="multiple"
                allowClear
                // showSearch
                // filterOption={(input, option) =>
                //   fuzzySearch(
                //     toLowerCaseNonAccentVietnamese(String(option?.children ?? "")),
                //     toLowerCaseNonAccentVietnamese(input)
                //   )
                // }
                placeholder={t("users.placeholders.role")}
                onSelect={(value) => {
                  if (value === "STUDENT") {
                    restFormProps?.form?.setFieldValue("roles", ["STUDENT"]);
                  } else {
                    const _roles =
                      roles?.filter((it) => it !== "STUDENT") ?? [];
                    restFormProps?.form?.setFieldValue(
                      "roles",
                      _roles?.concat(value)
                    );
                  }
                }}
              >
                {ROLE_LIST(t)
                  .filter((role) => role.isCreatable)
                  .map((role) => (
                    <Select.Option key={role.value} value={role.value}>
                      {role.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("users.fields.position")}
              name={["position"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("users.errors.positionRequired"),
              //   },
              // ]}
            >
              <Input
                placeholder={t("users.placeholders.position")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
