import { DeleteButton, EditButton, Show } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Row } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import TextBox from "components/textBox";
import { API_RESOURCES } from "config";
import { IExtraClass, ITimeTable } from "interfaces";
import { TimeTableTable } from "./private/timeTableTable";
import { formatOperationTime } from "./private/util";
import { formatSchoolYear } from "utils/modelHelper";
import useUserRole from "hooks/useUserRole";

export const ExtraClassShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const { list } = useNavigation();
  const t = useTranslate();

  const { queryResult } = useShow<IExtraClass, HttpError>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const [timeTableList, setTimeTableList] = useState<ITimeTable[]>([]);

  const updateTimeTableList = useCallback((newTimeTableList: ITimeTable[]) => {
    setTimeTableList(newTimeTableList);
  }, []);

  const initialTimeTableList = useMemo(
    () => queryResult?.data?.data?.timeTables || [],
    [queryResult]
  );

  useEffect(() => {
    updateTimeTableList(
      initialTimeTableList.map((timeTable, index) => ({
        ...timeTable,
        key: index + 1,
      }))
    );
  }, [initialTimeTableList, updateTimeTableList]);

  const { hasAdminRole, hasManagerRole } = useUserRole();
  const canEditDelete = hasAdminRole || hasManagerRole;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={
        <>
          {resource?.canEdit && canEditDelete && (
            <EditButton disabled={isLoading} type="primary" />
          )}
          {resource?.meta?.canDelete && canEditDelete && (
            <DeleteButton
              disabled={isLoading}
              onSuccess={() => {
                list(API_RESOURCES.extraClasses);
              }}
              errorNotification={(error) => {
                return {
                  type: "error",
                  message:
                    (error as any)?.message ??
                    t("extra_class.notifications.deleteError"),
                };
              }}
              successNotification={{
                type: "success",
                message: t("extra_class.notifications.deleteSuccess"),
              }}
            />
          )}
        </>
      }
    >
      <Row gutter={[16, 24]}>
        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={formatSchoolYear(record?.schoolYear)}
            label={t("extra_class.fields.schoolYear")}
          />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox value={record?.code} label={t("extra_class.fields.code")} />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox value={record?.name} label={t("extra_class.fields.name")} />
        </Col>

        <Col xs={24} md={12} lg={8}>
          <TextBox
            value={formatOperationTime(record?.startDate, record?.endDate)}
            label={t("extra_class.fields.operatingTime")}
          />
        </Col>

        <Col xs={24}>
          <TextBox
            valueStyles={{ minHeight: 100 }}
            value={record?.description}
            label={t("extra_class.fields.description")}
          />
        </Col>

        <Col xs={24}>
          <TimeTableTable timeTableList={timeTableList} hasShow />
        </Col>
      </Row>
    </Show>
  );
};
