import { useTranslate } from "@refinedev/core";
import { Form, Input } from "antd";

interface PageListProps {
  children?: React.ReactNode;
  /* Search props */
  searchKey?: string;
  searchValue?: string;
  onSearch?: () => void;
  searchFormProps?: any;
  /* Action props */
  actionButtons?: Array<React.ReactNode>;
}

export const PageList: React.FC<PageListProps> = ({
  children,
  searchKey = "q",
  searchValue = "",
  onSearch,
  searchFormProps = {},
  actionButtons = [],
}) => {
  const t = useTranslate();

  const { form, ...restFormProps } = searchFormProps;

  return (
    <div style={{ backgroundColor: "#fff", borderRadius: 12 }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 16,
          gap: 16,
        }}
      >
        <Form
          layout="vertical"
          {...restFormProps}
          form={form}
          initialValues={{ [searchKey]: searchValue }}
        >
          <Form.Item name={searchKey} style={{ marginBottom: 0 }}>
            <Input.Search
              allowClear
              placeholder={t("placeholders.input_search")}
              style={{ width: 230 }}
              onSearch={onSearch}
            />
          </Form.Item>
        </Form>

        <div style={{ display: "flex", gap: 12, flexWrap: "wrap" }}>
          {actionButtons}
        </div>
      </div>

      {children}
    </div>
  );
};
