import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { Show, useModal, useTable } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { App, Button, Col, Modal, Row, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import axiosInstance from "axiosInstance";
import TextBox from "components/textBox";
import { DATE_FORMAT } from "config";
import { INormalClass, ITimeTableReportNormalClass } from "interfaces";
import { downloadData } from "utils/domHelper";
import {
  formatClassPeriod,
  formatSchoolYear,
  formatUser,
} from "utils/modelHelper";
import TimeTableReportNormalClass from "./private/TimeTableReportNormalClass";
import HighlightTimetableStatus from "components/highlightTimetableStatus";

export const ReportNormalClassShow: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const { id } = useParams();
  const t = useTranslate();
  const { notification } = App.useApp();

  const {
    tableProps: { loading, ...restTableProps },
    tableQueryResult,
  } = useTable<ITimeTableReportNormalClass, HttpError>({
    resource: `reports/normal_class/timetable/${id}`,
    syncWithLocation: true,
    queryOptions: {
      enabled: Boolean(id),
    },
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const apiUrl = useApiUrl();
  const { data: normalClassData, isLoading: isNormalClassLoading } =
    useCustom<INormalClass>({
      url: `${apiUrl}/normal_class/${id}`,
      method: "get",
    });
  const normalClassRecord = normalClassData?.data;

  const isTableLoading =
    tableQueryResult?.isFetching ||
    tableQueryResult?.isRefetching ||
    tableQueryResult?.isLoading;

  const [timetableReportNormalClass, setTimetableReportNormalClass] =
    useState<ITimeTableReportNormalClass | null>(null);

  const {
    modalProps: modalTimeTableReportProps,
    show: showTimeTableReport,
    close: closeTimeTableReport,
  } = useModal();

  const [isRefetchingExport, setIsRefetchingExport] = useState(false);

  const refetchExport = async () => {
    try {
      setIsRefetchingExport(true);
      const { data } = await axiosInstance.get(
        `${apiUrl}/reports/normal_class/export/${id}`,
        {
          responseType: "blob",
        }
      );
      downloadData(data, t("reports_normal_classes.exports.fileName"));
      notification.success({
        message: t("reports_normal_classes.notifications.exportSuccess"),
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.message ??
          t("reports_normal_classes.notifications.exportError"),
      });
    } finally {
      setIsRefetchingExport(false);
    }
  };

  return (
    <>
      <Show
        isLoading={isTableLoading || isNormalClassLoading}
        headerButtons={[
          <Button
            key="download"
            icon={<CloudDownloadOutlined />}
            loading={isRefetchingExport}
            disabled={isRefetchingExport}
            onClick={() => refetchExport()}
          >
            {t("buttons.export")}
          </Button>,
        ]}
      >
        <Row gutter={[16, 24]} style={{ marginBottom: 32 }}>
          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={formatSchoolYear(normalClassRecord?.schoolYear)}
              label={t("normal_class.fields.schoolYear")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={normalClassRecord?.name}
              label={t("normal_class.fields.name")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={normalClassRecord?.code}
              label={t("normal_class.fields.code")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={formatClassPeriod(normalClassRecord?.classPeriod)}
              label={t("normal_class.fields.classPeriod")}
            />
          </Col>

          <Col xs={24} md={12} lg={8}>
            <TextBox
              value={normalClassRecord?.teachers
                ?.map((teacher) => formatUser(teacher))
                .join(", ")}
              label={t("normal_class.fields.teacher")}
            />
          </Col>
        </Row>

        <Typography.Title level={5} style={{ color: "#00748F" }}>
          {t("reports_normal_classes.titles.detail_list")}
        </Typography.Title>

        <Table
          {...restTableProps}
          rowKey={(record) => `${record?.date}-${record?.classPeriod?.id}`}
          bordered
        >
          <Table.Column
            dataIndex="idx"
            title={t("reports_normal_classes.fields.idx")}
          />
          <Table.Column
            dataIndex="date"
            title={t("reports_normal_classes.fields.date")}
            render={(date) => (date ? dayjs(date).format(DATE_FORMAT) : "")}
          />
          <Table.Column
            dataIndex="classPeriod"
            title={t("reports_normal_classes.fields.classPeriod")}
            render={(classPeriod) => formatClassPeriod(classPeriod)}
          />
          <Table.Column
            dataIndex="timetableStatus"
            title={t("common.status")}
            render={(timetableStatus) => (
              <HighlightTimetableStatus status={timetableStatus} />
            )}
          />
          <Table.Column
            dataIndex="totalStudent"
            title={t("reports_normal_classes.fields.totalStudent")}
          />
          <Table.Column
            dataIndex="attendedStudents"
            title={t("reports_normal_classes.fields.attendedStudents")}
            render={(attendedStudents) => attendedStudents?.length ?? ""}
          />
          <Table.ColumnGroup
            title={t("reports_normal_classes.fields.absented")}
          >
            <Table.Column
              dataIndex="absentedWithNoticeStudents"
              title={t(
                "reports_normal_classes.fields.absentedWithNoticeStudents"
              )}
              render={(absentedWithNoticeStudents) =>
                absentedWithNoticeStudents?.length ?? ""
              }
            />
            <Table.Column
              dataIndex="absentedStudents"
              title={t("reports_normal_classes.fields.absentedStudents")}
              render={(absentedStudents) => absentedStudents?.length ?? ""}
            />
            <Table.Column
              dataIndex="lateStudents"
              title={t("reports_normal_classes.fields.lateStudents")}
              render={(lateStudents) => lateStudents?.length ?? ""}
            />
            <Table.Column
              dataIndex="otherReasonStudents"
              title={t("reports_normal_classes.fields.otherReasonStudents")}
              render={(otherReasonStudents) =>
                otherReasonStudents?.length ?? ""
              }
            />
            <Table.Column
              dataIndex="notProcessStudents"
              title={t("reports_normal_classes.fields.notProcessStudents")}
              render={(notProcessStudents) => notProcessStudents?.length ?? ""}
            />
          </Table.ColumnGroup>
          <Table.Column
            title={t("reports_normal_classes.fields.actions")}
            dataIndex="actions"
            render={(_, record: ITimeTableReportNormalClass) => {
              return (
                <Space>
                  {resource?.canShow && (
                    <Button
                      size="small"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        showTimeTableReport();
                        setTimetableReportNormalClass(record);
                      }}
                    />
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Show>

      <Modal
        {...modalTimeTableReportProps}
        width={900}
        title={t("reports_normal_classes.details.title")}
        onCancel={() => {
          setTimetableReportNormalClass(null);
          closeTimeTableReport();
        }}
        footer={null}
      >
        {timetableReportNormalClass && (
          <TimeTableReportNormalClass record={timetableReportNormalClass} />
        )}
      </Modal>
    </>
  );
};
