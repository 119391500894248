import { Create, useForm } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { Col, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";

import { DatePicker, RangePicker } from "components/datepicker";
import {
  API_RESOURCES,
  DATE_FORMAT,
  DATE_FORMAT_2,
  DEFAULT_INPUT_MAX_LENGTH,
  DEFAULT_TEXT_AREA_MAX_LENGTH,
} from "config";
import { IExtraClass, ITimeTable } from "interfaces";
import { TimeTableTable } from "./private/timeTableTable";

export const ExtraClassCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    formProps: { onFinish: _, form: formCreateExtraClass, ...restFormProps },
    saveButtonProps,
  } = useForm<IExtraClass, HttpError>({
    errorNotification(error) {
      return {
        type: "error",
        message: error?.message ?? t("extra_class.notifications.createError"),
      };
    },
    successNotification: {
      type: "success",
      message: t("extra_class.notifications.createSuccess"),
    },
  });

  const { list } = useNavigation();
  const apiUrl = useApiUrl();
  const { setWarnWhen } = useWarnAboutChange();
  const [timeTableList, setTimeTableList] = useState<ITimeTable[]>([]);
  const { mutate: createExtraClass } = useCustomMutation<IExtraClass>();

  const handleCreateExtraClass = async (values: any) => {
    createExtraClass(
      {
        url: `${apiUrl}/extra_class/create`,
        method: "post",
        values: {
          ...values,
          startDate:
            values?.operatingTime?.length === 2
              ? dayjs(values?.operatingTime[0]).format(DATE_FORMAT_2)
              : undefined,
          endDate:
            values?.operatingTime?.length === 2
              ? dayjs(values?.operatingTime[1]).format(DATE_FORMAT_2)
              : undefined,
          timeTables:
            timeTableList?.map((timeTable) => ({
              date: dayjs(timeTable.date).format(DATE_FORMAT_2),
              classPeriodId: timeTable.classPeriod?.id,
              teachers: timeTable?.teachers?.map((teacher) => teacher.id),
              students: timeTable?.students?.map((student) => student.id),
            })) ?? [],
          operatingTime: undefined,
          description: values?.description ?? "",
          schoolYear: values?.schoolYear
            ? String(dayjs(values.schoolYear).get("year"))
            : null,
        },
        errorNotification(error) {
          return {
            type: "error",
            message:
              error?.message ?? t("extra_class.notifications.createError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("extra_class.notifications.createSuccess"),
        },
      },
      {
        onSuccess() {
          setWarnWhen?.(false);
          setTimeout(() => {
            list(API_RESOURCES.extraClasses);
          }, 100);
        },
      }
    );
  };

  const updateTimeTableList = useCallback(
    (newTimeTableList: ITimeTable[]) => {
      setTimeTableList(newTimeTableList);
      formCreateExtraClass?.setFieldsValue({
        timeTables: newTimeTableList.map((item) => item.id),
      });
    },
    [formCreateExtraClass]
  );

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...restFormProps}
        form={formCreateExtraClass}
        layout="vertical"
        onFinish={(values) => handleCreateExtraClass(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("extra_class.fields.schoolYear")}
              name={["schoolYear"]}
              rules={[
                {
                  required: true,
                  message: t("extra_class.errors.schoolYearRequired"),
                },
              ]}
            >
              <DatePicker
                placeholder={t("extra_class.placeholders.schoolYear")}
                style={{ width: "100%" }}
                picker="year"
                disabledDate={(date) =>
                  date < dayjs(new Date()).startOf("years").startOf("days")
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("extra_class.fields.code")}
              name={["code"]}
              rules={[
                {
                  required: true,
                  message: t("extra_class.errors.codeRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("extra_class.placeholders.code")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("extra_class.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                  message: t("extra_class.errors.nameRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("extra_class.placeholders.name")}
                maxLength={DEFAULT_INPUT_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={t("extra_class.fields.operatingTime")}
              name={["operatingTime"]}
              rules={[
                {
                  required: true,
                  message: t("extra_class.errors.operatingTimeRequired"),
                },
              ]}
            >
              <RangePicker format={DATE_FORMAT} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("extra_class.fields.description")}
              name={["description"]}
              // rules={[
              //   {
              //     required: true,
              //     message: t("extra_class.errors.timeRequired"),
              //   },
              // ]}
            >
              <Input.TextArea
                rows={4}
                placeholder={t("extra_class.placeholders.description")}
                maxLength={DEFAULT_TEXT_AREA_MAX_LENGTH}
                showCount
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <TimeTableTable
              timeTableList={timeTableList}
              onTimeTableListChanged={(newTimeTableList) => {
                updateTimeTableList(newTimeTableList);
              }}
              hasAdd
              hasDelete
              hasEdit
              hasClone
            />
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
