import { TitleProps as RefineTitleProps, useLink } from "@refinedev/core";
import React, { CSSProperties } from "react";

interface TitleProps extends RefineTitleProps {
  imgWidth?: number;
  imgCollapsedWidth?: number;
  imgMaxHeight?: number;
  imgCollapsedMaxHeight?: number;
  linkStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  title?: React.ReactNode;
}

export const Title: React.FC<TitleProps> = ({
  collapsed,
  imgWidth = 80,
  imgCollapsedWidth = 60,
  imgMaxHeight = 80,
  imgCollapsedMaxHeight = 60,
  linkStyle = {},
  imageStyle = {},
  title,
}) => {
  const Link = useLink();
  const to = window.location.pathname.includes("login")
    ? `${window.location.pathname}${window.location.search}`
    : "/";

  return (
    <Link
      to={to}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: window.location.pathname.includes("login")
          ? "default"
          : "pointer",
        ...linkStyle,
      }}
    >
      {collapsed ? (
        <div
          style={{
            borderRadius: 10,
            overflow: "hidden",
            marginTop: 14,
          }}
        >
          <img
            src={"/logo-collapsed.svg"}
            alt="logo"
            style={{
              width: imgCollapsedWidth,
              maxHeight: imgCollapsedMaxHeight,
              backfaceVisibility: "hidden",
              // padding: "20px 24px 16px",
              ...imageStyle,
            }}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              borderRadius: 10,
              overflow: "hidden",
              marginTop: 14,
              marginBottom: 6,
            }}
          >
            <img
              src={"/logo-2.svg"}
              alt="logo"
              style={{
                width: imgWidth,
                maxHeight: imgMaxHeight,
                // padding: "20px 24px 16px",
                backfaceVisibility: "hidden",
                ...imageStyle,
              }}
            />
          </div>
          {title}
        </>
      )}
    </Link>
  );
};
