import { RefineLayoutHeaderProps } from "@refinedev/antd";
import { Layout as AntdLayout, Space, theme } from "antd";

import { LanguageDropdown } from "./LanguageDropdown";
import { MenuPopover } from "./MenuPopover";
// import { NotificationPopover } from "./NotificationPopover";

type HeaderProps = RefineLayoutHeaderProps;

export const Header: React.FC<HeaderProps> = () => {
  const { token } = theme.useToken();

  // const { data: user } = useGetIdentity<IUserIdentity>();

  // const { mode, setMode } = useContext(ColorModeContext);

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        borderBottom: "1px solid #eaeaea",
        // boxShadow:
        //   "0 10px 15px -3px rgba(0,0,0,.04),0 4px 6px -2px rgba(0,0,0,.02)",
        // boxShadow:
        //   "0px 2px 4px -2px rgba(17, 15, 71, 0.12), 0px 4px 4px -2px rgba(17, 15, 71, 0.08)",
      }}
    >
      <Space size="large">
        <LanguageDropdown />

        {/* <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
        /> */}

        {/* <Space style={{ marginLeft: "8px" }} size="middle">
          {user?.name && <Text strong>{user.name}</Text>}
          {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
        </Space> */}

        {/* <NotificationPopover /> */}

        <MenuPopover />
      </Space>
    </AntdLayout.Header>
  );
};
