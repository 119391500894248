import { DatePicker as AntDatePicker } from "antd";
import { Dayjs } from "dayjs";
import { PickerProps } from "antd/lib/date-picker/generatePicker";

import "dayjs/locale/vi";
import viVn from "antd/es/date-picker/locale/vi_VN";

export const DatePicker = (props: PickerProps<Dayjs>) => (
  <AntDatePicker {...props} locale={viVn} />
);
