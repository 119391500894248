import { DatePicker as AntDatePicker } from "antd";
import { Dayjs } from "dayjs";
import { RangePickerProps } from "antd/es/date-picker/generatePicker";

import "dayjs/locale/vi";
import viVn from "antd/es/date-picker/locale/vi_VN";

export const RangePicker = (props: RangePickerProps<Dayjs>) => (
  <AntDatePicker.RangePicker {...props} locale={viVn} />
);
