import { ShowButton, useTable } from "@refinedev/antd";
import {
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { useDebounceFn } from "ahooks";
import { Space, Table } from "antd";
import React from "react";

import { PageList } from "components/pages";
import { API_RESOURCES } from "config";
import { IClassPeriod, INormalClass, IUser } from "interfaces";
import {
  formatClassPeriod,
  formatSchoolYear,
  formatUser,
} from "utils/modelHelper";

export const ReportNormalClassList: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const t = useTranslate();

  const { tableProps, searchFormProps, filters } = useTable<
    INormalClass,
    HttpError
  >({
    resource: API_RESOURCES.normalClasses,
    syncWithLocation: true,
    pagination: {
      mode: "server",
    },
    onSearch: ({ q }: { q: string }) => {
      return [
        {
          field: "q",
          operator: "eq",
          value: q,
        },
      ];
    },
  });

  const { form } = searchFormProps;
  const { run: runSearch } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <PageList
      searchValue={getDefaultFilter("q", filters, "eq")}
      searchFormProps={searchFormProps}
      onSearch={() => runSearch()}
      actionButtons={[]}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" title={t("normal_class.fields.idx")} />
        <Table.Column
          dataIndex="schoolYear"
          title={t("normal_class.fields.schoolYear")}
          render={(schoolYear) => formatSchoolYear(schoolYear)}
        />
        <Table.Column dataIndex="name" title={t("normal_class.fields.name")} />
        <Table.Column dataIndex="code" title={t("normal_class.fields.code")} />
        <Table.Column
          dataIndex="classPeriod"
          title={t("normal_class.fields.classPeriod")}
          render={(classPeriod: IClassPeriod) => formatClassPeriod(classPeriod)}
        />
        <Table.Column
          dataIndex="teachers"
          title={t("normal_class.fields.teacher")}
          render={(teachers: IUser[]) => {
            return teachers?.map((teacher) => formatUser(teacher)).join(", ");
          }}
        />
        <Table.Column
          title={t("normal_class.fields.actions")}
          dataIndex="actions"
          render={(_, record: INormalClass) => {
            return (
              <Space>
                {resource?.canShow && (
                  <ShowButton hideText size="small" recordItemId={record.id} />
                )}
              </Space>
            );
          }}
        />
      </Table>
    </PageList>
  );
};
